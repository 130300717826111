
	import { Component, Prop, Vue } from "vue-property-decorator";

	@Component
	export default class ButtonSelect extends Vue {
		@Prop() public value!: number;
		@Prop() public options!: string[];
		@Prop() public btnClass?: string;
		@Prop(Boolean) public arrow?: boolean;
	}
