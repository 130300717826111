var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import dialogs from "./dialogs";
export const settings = {
    adapter: {
        getAccessToken: () => null,
        getRefreshToken: () => null,
        setToken(v) { },
    },
    login() { },
    logout() { },
};
export const TEN_SECONDS = 10000;
export const OK = 200;
const FORCE_LOGOUT = 410;
const BAD_REQUEST = 400;
const TOKEN_EXPIRE = 498;
const pendingControllers = new Set();
globalThis.fetchWithTimeout =
    function (resource, options = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const { timeout = 8000 } = options;
            const controller = new AbortController();
            pendingControllers.add(controller);
            let isTimeout = false;
            const id = setTimeout(() => {
                isTimeout = true;
                controller.abort();
            }, timeout);
            try {
                const response = yield fetch(resource, Object.assign(Object.assign({}, options), { signal: controller.signal }));
                clearTimeout(id);
                return response;
            }
            catch (e) {
                const timeoutErr = e instanceof Error && e.message.includes("time");
                if (!isTimeout && !timeoutErr)
                    throw true;
                throw e;
            }
            finally {
                pendingControllers.delete(controller);
            }
        });
    };
function tryFetch(url, token, data, timeout) {
    const headers = {
        'Content-Type': 'application/json',
    };
    if (token)
        headers.AccessToken = token;
    return fetchWithTimeout(url, {
        body: JSON.stringify(data),
        cache: 'no-cache',
        headers,
        timeout,
        method: 'POST',
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getJson(response) {
    return __awaiter(this, void 0, void 0, function* () {
        let text = yield response.text();
        return JSON.parse(text, (key, value) => {
            // 自動將日期字串轉換成 JS 日期時間物件
            key = key.toLowerCase();
            if ((key.endsWith('time') || key.endsWith('date') || key.endsWith('birthday') || key == 'start' || key == 'end') && typeof value == 'string') {
                value = value.replace(/\+.+$/, ""); // 拿掉時間夾帶的時區資訊，使得顯示出來的時間不受到手機所在時區影響
                let timestamp = Date.parse(value);
                if (!isNaN(timestamp))
                    return new Date(timestamp);
            }
            return value;
        });
    });
}
function onForceLogout() {
    if (settings.adapter.getAccessToken()) {
        dialogs.alert("請重新登入", "已經有另外一台裝置登入，將自動登出", "warning");
        cancelAllPosts();
        settings.logout();
    }
    throw true;
}
export function refresh(token, timeout) {
    return __awaiter(this, void 0, void 0, function* () {
        let response = yield tryFetch('/api/Login/Refresh', token, {}, timeout);
        if (response.status == FORCE_LOGOUT)
            onForceLogout();
        let json = response.status == OK ? yield getJson(response) : null;
        if (json)
            settings.adapter.setToken(json.Value);
        return response;
    });
}
let connectionFailing = false; // 用來控制連線錯誤訊息在短時間內只會觸發一次
function connectionFailed() {
    return __awaiter(this, void 0, void 0, function* () {
        if (connectionFailing)
            return;
        connectionFailing = true;
        yield dialogs.alert("無法聯繫伺服器，請稍後再試", "網路連線失敗", "danger");
        connectionFailing = false;
    });
}
globalThis.post = function (url, data = {}, timeout = 5000) {
    return __awaiter(this, void 0, void 0, function* () {
        let response;
        try {
            response = yield tryFetch(url, settings.adapter.getAccessToken(), data, timeout);
        }
        catch (e) {
            if (e !== true)
                connectionFailed(); // 不用等候
            throw true;
        }
        if (response.status == FORCE_LOGOUT)
            onForceLogout();
        if (response.status == TOKEN_EXPIRE) {
            try {
                yield requestRefresh();
                // 再次嘗試相同的 API
                response = yield tryFetch(url, settings.adapter.getAccessToken(), data, timeout);
            }
            catch (e) {
                if (refreshing) {
                    refreshing = false;
                    dialogs.alert("請重新登入", "登入已失效", "warning");
                    cancelAllPosts();
                    settings.login();
                }
                throw true;
            }
        }
        if (response.status == BAD_REQUEST) {
            // 這邊理論上只有在開發環境會發生，此時不顯示錯誤直接回到登入
            cancelAllPosts();
            settings.login();
            throw true;
        }
        if (response.status != OK) {
            dialogs.alert("錯誤代碼：" + response.status, "伺服器發生錯誤", "warning");
            throw true;
        }
        const result = yield getJson(response);
        if (result.Valid)
            return result.Value;
        else
            throw new Error(result.ResultMessage);
    });
};
export function cancelAllPosts() {
    for (let controller of pendingControllers)
        controller.abort();
    pendingControllers.clear();
}
let refreshing = false;
let refreshPromise;
function requestRefresh() {
    if (refreshing)
        return refreshPromise;
    refreshing = true;
    // eslint-disable-next-line no-async-promise-executor
    return refreshPromise = new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
        let refreshToken = settings.adapter.getRefreshToken();
        if (!refreshToken) {
            reject(new Error());
        }
        else {
            const response = yield refresh(refreshToken, TEN_SECONDS);
            if (response.status != OK) {
                reject(new Error());
            }
            else {
                refreshing = false;
                resolve();
            }
        }
    }));
}
