var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { today } from "@common/date";
import DateTimeRangePicker from "./dateTimeRangePicker/DateTimeRangePicker.vue";
let DateRange = class DateRange extends Vue {
    constructor() {
        super(...arguments);
        this.active = 0;
        this.value = [];
    }
    get end() {
        return this.value[1];
    }
    onEndChange(v) {
        if (v && v.getHours() == 0 && v.getMinutes() == 0 && v.getSeconds() == 0) {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            v.setHours(23, 59, 59, 999);
        }
    }
    created() {
        this.reset(this.init);
    }
    reset(init) {
        if (init !== undefined)
            this.active = init;
    }
    openDialog() {
        return __awaiter(this, void 0, void 0, function* () {
            const active = this.active;
            const value = this.value.concat();
            this.active = 4;
            if (yield this.$refs.picker.show()) {
                this.$emit('change', this.value);
            }
            else {
                this.active = active;
                this.value = value;
            }
        });
    }
    get filter() {
        let max = this.max;
        let min = this.min;
        if (min) {
            min = new Date(min.getTime());
            min.setHours(0, 0, 0, 0);
        }
        return (date) => (!max || date <= max) && (!min || date >= min);
    }
    set(active, days) {
        this.active = active;
        const tomorrow = today().addDays(1).addSeconds(-1);
        const ago = today().addDays(-days + 1);
        this.value = [ago, tomorrow];
        this.$emit('change', this.value);
    }
};
__decorate([
    Prop(Date)
], DateRange.prototype, "max", void 0);
__decorate([
    Prop(Date)
], DateRange.prototype, "min", void 0);
__decorate([
    Prop(Boolean)
], DateRange.prototype, "noScroll", void 0);
__decorate([
    Prop()
], DateRange.prototype, "init", void 0);
__decorate([
    Watch("end")
], DateRange.prototype, "onEndChange", null);
DateRange = __decorate([
    Component({ components: { DateTimeRangePicker } })
], DateRange);
export default DateRange;
