/* eslint-disable @typescript-eslint/no-magic-numbers */
export const setScrollState = {
    timeout: 0,
};
export function setScroll(scroll) {
    // Safari 裡面會需要一個難以預測的延遲才能正確還原捲動，
    // 因此這裡的辦法是不斷嘗試直到還原成功為止
    let main = document.querySelector('main');
    if (Math.abs(main.scrollTop - scroll) < 5)
        return; // 可能會有浮點誤差，要小心
    main.scrollTop = scroll;
    setScrollState.timeout = window.setTimeout(() => setScroll(scroll), 50);
}
