var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import * as bootstrap from 'bootstrap';
import './dialog.css';
let opened = false;
export function anyOpen() {
    return opened;
}
// @ts-ignore
let Dialog = class Dialog extends Vue {
    constructor() {
        super(...arguments);
        this.last = Promise.resolve();
        this.message = "";
    }
    mounted() {
        this.modal = new bootstrap.Modal(this.$el, { backdrop: 'static' });
    }
    show(message) {
        let current = new Promise(resolve => {
            this.last.then(() => {
                this.message = message;
                this.$el.addEventListener('hidden.bs.modal', () => {
                    opened = false;
                    resolve(this.value);
                }, { once: true });
                this.modal.show();
                opened = true;
            });
        });
        this.last = current;
        return current;
    }
    close() {
        this.modal.hide();
    }
};
Dialog = __decorate([
    Component
], Dialog);
export default Dialog;
