var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from "vue-property-decorator";
import Teleport from "vue2-teleport";
const TOAST_TIMER = 2000;
let Toast = class Toast extends Vue {
    show() {
        this.$refs.toast.classList.add("show");
        setTimeout(() => this.$refs.toast.classList.remove("show"), TOAST_TIMER);
    }
};
Toast = __decorate([
    Component({ components: { Teleport } })
], Toast);
export default Toast;
