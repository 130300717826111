import { settings } from "@common/communication";
import router from "@/router";
import store from "@/store";
export function login(result, username) {
    store.commit("setAccessToken", result);
    if (username) {
        localStorage.setItem("username", username);
    }
    else {
        localStorage.removeItem("username");
    }
}
export function logout() {
    localStorage.removeItem("token");
    store.commit("setAccessToken", null);
    router.replace("/login");
}
settings.logout = logout;
