var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import { Tooltip } from 'bootstrap';
const TOOLTIP_TIME = 3000;
let Help = class Help extends Vue {
    constructor() {
        super(...arguments);
        this.tooltip = null;
    }
    mounted() {
        this.tooltip = Tooltip.getOrCreateInstance(this.$el);
    }
    beforeDestroy() {
        // 在這邊直接立刻 dispose 掉的話，
        // tooltip 隱藏的動畫結束後的 callback 裡面會觸發錯誤，
        // 解法就是先隱藏然後延後 dispose。
        this.tooltip.hide();
        setTimeout(() => this.tooltip.dispose(), TOOLTIP_TIME);
    }
};
__decorate([
    Prop()
], Help.prototype, "title", void 0);
Help = __decorate([
    Component
], Help);
export default Help;
