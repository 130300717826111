import Login from '@/views/login/Index.vue';
/////////////////////////////////////
// 登入
const routes = [
    {
        path: '/login',
        component: Login,
        meta: {
            anonymous: true,
            root: true,
        },
        props: route => ({ callback: route.query.callback }),
    },
    {
        path: '/login/register/:username?',
        props: true,
        component: () => import(/* webpackChunkName: "login" */ '@/views/login/Register.vue'),
        meta: {
            anonymous: true,
        },
    },
    {
        path: '/login/forget',
        component: () => import(/* webpackChunkName: "login" */ '@/views/login/Forget.vue'),
        meta: {
            anonymous: true,
        },
    },
    {
        path: '/login/terms',
        component: () => import(/* webpackChunkName: "login" */ '@/views/login/Terms.vue'),
        meta: {
            anonymous: true,
        },
    },
];
export default routes;
