/////////////////////////////////////
// 入帳 / 提款
const routes = [
    {
        path: '/fund',
        component: () => import(/* webpackChunkName: "fund" */ '@/views/fund/Index.vue'),
        meta: {
            root: true,
        },
    },
    {
        path: '/fund/commonbank',
        component: () => import(/* webpackChunkName: "fund" */ '@/views/fund/CommonBank.vue'),
    },
    {
        path: '/fund/editaccount/:id?',
        props: true,
        component: () => import(/* webpackChunkName: "fund" */ '@/views/fund/EditAccount.vue'),
    },
    {
        path: '/fund/withdrawrecord',
        component: () => import(/* webpackChunkName: "fund" */ '@/views/fund/WithdrawRecord.vue'),
    },
    {
        path: '/fund/withdraw',
        component: () => import(/* webpackChunkName: "fund" */ '@/views/fund/Withdraw.vue'),
    },
    {
        path: '/fund/record/:id',
        props: true,
        component: () => import(/* webpackChunkName: "fund" */ '@/views/fund/Record.vue'),
    },
];
export default routes;
