const DELAY = 100;
// 修正 Kendo 下拉選單當螢幕小鍵盤跑出來的瞬間跑版的問題
window.addEventListener("resize", () => {
    setTimeout(fixKendo, DELAY); // 稍加延遲，確保東西都出現了
});
function fixKendo() {
    const container = document.querySelector("div.k-animation-container");
    const input = document.activeElement;
    if (!container || !input || input.tagName == "BODY")
        return;
    const bottom = input.getBoundingClientRect().bottom;
    container.style.top = bottom + "px";
}
