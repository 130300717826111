
	import { Component, Prop, Vue } from "vue-property-decorator";
	import ButtonSelect from "./ButtonSelect.vue";

	export type Selector<T> = (v: T) => number | string;

	export function sort<T>(data: T[], selector: Selector<T>, ascending: boolean): T[] {
		const list = data.concat();
		const comparator = (a: T, b: T) => {
			let v1 = selector(a), v2 = selector(b);
			let compare: number = 0;
			if(typeof v1 == "number" && typeof v2 == "number") {
				// 數值比較
				compare = v1 - v2;
			} else {
				// 字串比較
				v1 = v1?.toString() ?? "";
				v2 = v2?.toString() ?? "";
				if(v1 > v2) compare = 1;
				if(v1 < v2) compare = -1;
			}
			return compare * (ascending ? 1 : -1);
		};
		return list.sort(comparator);
	}

	@Component({ components: { ButtonSelect } })
	export default class Sorter extends Vue {
		@Prop() public sorting!: [number, boolean];
		@Prop() public options!: string[];

		protected toggle(): void {
			Vue.set(this.sorting, 1, !this.sorting[1]);
		}
	}
