/////////////////////////////////////
// 基本資料
const routes = [
    {
        path: '/info',
        component: () => import(/* webpackChunkName: "info" */ '@/views/info/Index.vue'),
        meta: {
            root: true,
        },
    },
    {
        path: '/info/changepassword',
        component: () => import(/* webpackChunkName: "info" */ '@/views/info/ChangePassword.vue'),
    },
    {
        path: '/info/changePin',
        component: () => import(/* webpackChunkName: "info" */ '@/views/info/ChangePin.vue'),
    },
    {
        path: '/info/delaccount',
        component: () => import(/* webpackChunkName: "info" */ '@/views/info/DelAccount.vue'),
    },
    {
        path: '/info/scan',
        component: () => import(/* webpackChunkName: "info" */ '@/views/info/scan/Index.vue'),
    },
    {
        path: '/info/scan/ticket/:code/:id',
        props: true,
        component: () => import(/* webpackChunkName: "info" */ '@/views/info/scan/Ticket.vue'),
    },
];
export default routes;
