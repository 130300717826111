
	import { Component, Prop, Vue } from "vue-property-decorator";

	import { SM_CLASS } from ".";

	@Component
	export default class BtnSm extends Vue {
		@Prop({ default: "primary" }) public c!: string;
		@Prop() public to?: string;

		protected click(e: Event): void {
			if(this.to) this.$router.push(this.to);
			this.$emit("click", e);
		}

		protected readonly cls = SM_CLASS;
	}
