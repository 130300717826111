/* eslint-disable @typescript-eslint/no-magic-numbers */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import InputMasked from '@aymkdn/vue-input-masked';
const YEARS = 20;
let Calender = class Calender extends Vue {
    constructor() {
        super(...arguments);
        this.tempDate = "";
        this.tempTime = "";
        this.displayDate = new Date().datePart();
    }
    mounted() {
        if (this.value)
            this.onValueChanged(this.value);
        this.$refs.date.$el.addEventListener('blur', () => {
            if (!this.dateChange())
                this.tempDate = this.date;
        });
        this.$refs.time.$el.addEventListener('blur', () => {
            if (!this.timeChange())
                this.tempTime = this.time;
        });
    }
    onValueChanged(v) {
        this.displayDate = v.clone();
        this.tempDate = this.date;
        this.tempTime = this.time;
    }
    get yearOptions() {
        let start = new Date().getFullYear() - YEARS;
        let result = [];
        for (let i = 0; i <= YEARS + 10; i++)
            result.push(start + i);
        return result;
    }
    get startDate() {
        let result = this.displayDate.clone();
        result.setDate(1);
        result = result.addDays(-result.getDay());
        return result;
    }
    get offset() {
        var test = this.displayDate.clone();
        test.setDate(1);
        return test.getDay();
    }
    get days() {
        var test = this.displayDate.clone();
        test.setMonth(test.getMonth() + 1, 0);
        return test.getDate();
    }
    get dateAt() {
        var result = [];
        for (let i = 0; i < 42; i++) {
            result.push(this.startDate.clone().addDays(i));
        }
        return result;
    }
    classAt(pos) {
        const date = this.dateAt[pos];
        let result = date.getMonth() == this.displayDate.getMonth() ? "" : "text-muted bg-muted";
        if (this.value && date.toDateString() == this.value.toDateString())
            result += " selected";
        if (this.isDisabled(date))
            result += " disabled";
        return result;
    }
    isDisabled(date) {
        if (this.filter && !this.filter(date))
            return true;
        if (!this.other)
            return false;
        if (this.isStart)
            return date.datePart() > this.other.datePart();
        else
            return date.datePart() < this.other.datePart();
    }
    labelAt(pos) {
        return this.dateAt[pos].getDate().toString();
    }
    move(m) {
        this.displayDate.setMonth(this.displayDate.getMonth() + m);
        this.displayDate = this.displayDate.clone(); // 觸發變化
    }
    setYear(y) {
        this.displayDate.setFullYear(y);
        this.displayDate = this.displayDate.clone(); // 觸發變化
    }
    setMonth(m) {
        this.displayDate.setMonth(m - 1);
        this.displayDate = this.displayDate.clone(); // 觸發變化
    }
    select(pos) {
        let date = this.dateAt[pos];
        if (this.isDisabled(date))
            return;
        if (this.value === undefined && !this.isStart) {
            date = date.addDays(1).addSeconds(-1);
        }
        this.displayDate = date.clone();
        this.$emit("input", date);
    }
    spanClassAt(pos) {
        if (!this.value || !this.other)
            return "";
        const [a, b] = [this.other.datePart().getTime(), this.value.datePart().getTime()].sort();
        const date = this.dateAt[pos].datePart().getTime();
        if (a <= date && date <= b && this.dateAt[pos].toDateString() != this.value.toDateString())
            return "range";
    }
    get date() {
        var _a, _b;
        return (_b = (_a = this.value) === null || _a === void 0 ? void 0 : _a.date()) !== null && _b !== void 0 ? _b : "";
    }
    get time() {
        var _a, _b;
        return (_b = (_a = this.value) === null || _a === void 0 ? void 0 : _a.time()) !== null && _b !== void 0 ? _b : "";
    }
    dateChange() {
        var _a, _b;
        const timestamp = Date.parse(this.tempDate);
        if (!isNaN(timestamp)) {
            const date = new Date(timestamp);
            const temp = (_b = (_a = this.value) === null || _a === void 0 ? void 0 : _a.clone()) !== null && _b !== void 0 ? _b : date;
            temp.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
            if ((!this.other || (this.isStart && temp < this.other || !this.isStart && this.other < temp)) &&
                (!this.filter || this.filter(temp))) {
                this.$emit('input', temp);
                return true;
            }
        }
        return false;
    }
    timeChange() {
        var _a, _b;
        const timestamp = Date.parse("2000-01-01T" + this.tempTime);
        if (!isNaN(timestamp)) {
            const date = new Date(timestamp);
            const temp = (_b = (_a = this.value) === null || _a === void 0 ? void 0 : _a.clone()) !== null && _b !== void 0 ? _b : new Date();
            temp.setHours(date.getHours(), date.getMinutes(), 0, 0);
            if (!this.other || (this.isStart && temp < this.other || !this.isStart && this.other < temp)) {
                this.$emit('input', temp);
                return true;
            }
        }
        return false;
    }
};
__decorate([
    Prop()
], Calender.prototype, "value", void 0);
__decorate([
    Prop()
], Calender.prototype, "other", void 0);
__decorate([
    Prop()
], Calender.prototype, "timeMode", void 0);
__decorate([
    Prop()
], Calender.prototype, "label", void 0);
__decorate([
    Prop()
], Calender.prototype, "filter", void 0);
__decorate([
    Prop(Boolean)
], Calender.prototype, "isStart", void 0);
__decorate([
    Watch('value')
], Calender.prototype, "onValueChanged", null);
Calender = __decorate([
    Component({ components: { InputMasked } })
], Calender);
export default Calender;
