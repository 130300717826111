var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
const H = 100;
const G = 50;
let default_1 = class default_1 extends Vue {
    get maxPlay() {
        return Math.max(...this.data.map(item => item.play));
    }
    get maxGift() {
        if (!this.data)
            return 0;
        return Math.max(...this.data.map(item => item.gift));
    }
    playStyle(play) {
        if (this.maxPlay == 0)
            return `width:0`;
        return `width:${play / this.maxPlay * H}%;`;
    }
    giftStyle(gift) {
        if (this.maxGift == 0)
            return `width:0`;
        return `width:${gift / this.maxGift * G}%;`;
    }
};
__decorate([
    Prop(String)
], default_1.prototype, "title", void 0);
__decorate([
    Prop(Boolean)
], default_1.prototype, "go", void 0);
__decorate([
    Prop()
], default_1.prototype, "data", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
