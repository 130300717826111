var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as bootstrap from 'bootstrap';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MultiSelect } from '@progress/kendo-vue-dropdowns';
import { newQuery } from '@/models/Report';
import { LeapaItemTypeOptions } from "@/models/Item";
import FilterKiosk from "./FilterKiosk.vue";
import IncludeExclude from './IncludeExclude.vue';
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.LeapaItemTypeOptions = LeapaItemTypeOptions;
        this.value = false;
        this.options = [];
        this.filtered = [];
        this.temp = {};
    }
    mounted() {
        this.modal = new bootstrap.Modal(this.$el);
        post("/api/device").then(v => {
            if (this.noInactive)
                v = v.filter(d => d.IsActive);
            this.options = v;
            this.filtered = v.concat();
            this.options.forEach(o => o.DisplayName = o.Name + " " + o.DisplayCode);
        });
    }
    show() {
        this.value = false;
        return new Promise(resolve => {
            this.copy();
            this.modal.show();
            this.$el.addEventListener('hidden.bs.modal', () => resolve(this.value), { once: true });
        });
    }
    get unselected() {
        return this.options.filter(k => !this.temp.id.includes(k.KioskId));
    }
    onRange(e) {
        if (e.length < 2)
            return;
        if (!e[1])
            e[1] = e[0];
        [this.temp.start, this.temp.end] = e;
    }
    onOption(event) {
        let sel = event.target;
        let code = sel.value;
        this.temp.id.push(code);
        sel.value = "";
    }
    copy() {
        Vue.set(this, "temp", {
            start: this.query.start,
            end: this.query.end,
            mode: this.query.mode,
            init: this.query.init,
            tag: this.query.tag.concat(),
            exTag: this.query.exTag.concat(),
            category: this.query.category.concat(),
            exCategory: this.query.exCategory.concat(),
            types: this.query.types.concat(),
            itemName: this.query.itemName,
            kiosks: this.options.filter(o => this.query.id.includes(o.KioskId)),
        });
        if (!this.noDate)
            this.$refs.range.reset(this.query.init);
    }
    getKiosk(c) {
        let k = this.options.find(e => e.KioskId == c);
        if (!k)
            return "";
        return k.Name;
    }
    getKioskCode(c) {
        let k = this.options.find(e => e.KioskId == c);
        if (!k)
            return "";
        return k.DisplayCode;
    }
    remove(c) {
        this.temp.id.splice(this.temp.id.indexOf(c), 1);
    }
    ok() {
        // 去掉與當前篩選模式無關的條件
        if (this.temp.mode != 'tag') {
            this.temp.tag = [];
            this.temp.exTag = [];
        }
        if (this.temp.mode != 'cat') {
            this.temp.category = [];
            this.temp.exCategory = [];
        }
        if (this.temp.mode != 'id')
            this.temp.kiosks = [];
        if (this.temp.mode != 'item')
            this.temp.itemName = null;
        if (this.temp.mode != 'type')
            this.temp.types = [];
        Object.assign(this.query, this.temp, {
            kiosks: undefined,
            id: this.temp.kiosks.map(k => k.KioskId),
        });
        if (!this.noDate)
            this.query.init = this.$refs.range.active;
        this.value = true;
    }
    reset() {
        Object.assign(this.temp, newQuery());
        if (!this.noDate)
            this.$refs.range.reset(this.temp.init = 0);
    }
    filterKiosk(event) {
        const text = event.filter.value;
        this.filtered = this.options.filter(r => !text || r.DisplayName.includes(text));
    }
};
__decorate([
    Prop()
], default_1.prototype, "query", void 0);
__decorate([
    Prop()
], default_1.prototype, "tags", void 0);
__decorate([
    Prop()
], default_1.prototype, "categories", void 0);
__decorate([
    Prop(Boolean)
], default_1.prototype, "noDate", void 0);
__decorate([
    Prop(Boolean)
], default_1.prototype, "noInactive", void 0);
__decorate([
    Prop(Boolean)
], default_1.prototype, "noGoods", void 0);
__decorate([
    Prop(Date)
], default_1.prototype, "min", void 0);
default_1 = __decorate([
    Component({ components: { MultiSelect, FilterKiosk, IncludeExclude } })
], default_1);
export default default_1;
