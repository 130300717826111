import { Enum } from "@common/enum";
import type { MerchantKioskModel, MerchantKioskDetailModel } from "@common/models/kiosk";
import type { ReportModel } from "./Report";
import type { UploadModel } from "@common/models";

export interface KioskItemEditModel {
	Id: string;
	MerchantKioskId: string;
	MerchantKiosk: MerchantKioskModel;
	IsActive: boolean;
	StartTime: Date;
	Name: string;
	Price: number;
	Photo: UploadModel[];
	LeapaItemType: LeapaItemType | null;
}

export interface KioskItemIndexModel {
	KioskItem: KioskItemModel | null;
	MerchantKiosk: MerchantKioskModel
	Report: ReportModel;
	StartTime: Date;
	EndTime: Date | null;
}

export interface KioskItemModel {
	Id: string;
	StartTime: string | null;
	EndTime: string | null;
	Item: ItemModel;
}

export interface ItemModelBase {
	Id: string;
	PurchasePrice: number | null;
	SupplyStatus: ItemSupplyStatus;
	Stock: number | null;
	Unit: string;
}

export interface ItemModel extends ItemModelBase {
	Photo: string;
	Name: string;
	Data: ItemDataModel;
	CreateTime: Date;
}

export interface ItemEditModel extends ItemModelBase {
	MerchantName?: string;
	CodeName?: string;
	Data: ItemDataEditModel;
	Relation?: MerchantItemRelation[];
}

export interface MerchantItemRelation {
	MerchantId: string;
	Type: MerchantItemType;
}

export enum MerchantItemType {
	Issuer = 1,
	Dealer = 2,
	Collector = 4,
}

export enum ItemSupplyStatus {
	InStock = 1,
	OutOfStock = 0,
	Discontinued = -1,
}

export interface ItemDataModelBase {
	CodeName?: string;
	Name: string;
	FactoryCode?: string;
	Description: string;
	SellingPrice?: number;
	Unit?: string;
}

export interface ItemDataModel extends ItemDataModelBase {
	LeapaItemType: LeapaItemType | null;
	Photo: string[];
}

export interface ItemDataEditModel extends ItemDataModelBase {
	Photo: UploadModel[];
	Picture: UploadModel[];
}

export enum LeapaItemType {
	Snack = 10,
	Beverage = 20,
	InflatedPackage = 30,
	StuffedToy = 40,
	Toy = 50,
	Figurine = 60,
	Electronic = 70,
	Necessity = 80,
	Other = 9999,
}

export function leapaItemTypeString(type: LeapaItemType): string {
	switch(type) {
		case LeapaItemType.Snack: return "零食";
		case LeapaItemType.Beverage: return "飲料";
		case LeapaItemType.InflatedPackage: return "空氣包";
		case LeapaItemType.StuffedToy: return "娃娃";
		case LeapaItemType.Toy: return "玩具";
		case LeapaItemType.Figurine: return "公仔";
		case LeapaItemType.Electronic: return "三Ｃ";
		case LeapaItemType.Necessity: return "日用品";
		default: return "其它";
	}
}

export const LeapaItemTypeOptions = Enum.values(LeapaItemType).map(type => ({
	text: leapaItemTypeString(type),
	value: type,
}));
