import { today } from "@common/date";
export var KioskTransactionType;
(function (KioskTransactionType) {
    KioskTransactionType[KioskTransactionType["Coin"] = 0] = "Coin";
    KioskTransactionType[KioskTransactionType["Giftout"] = 1] = "Giftout";
    KioskTransactionType[KioskTransactionType["Remote"] = 2] = "Remote";
    KioskTransactionType[KioskTransactionType["Payment"] = 3] = "Payment";
    KioskTransactionType[KioskTransactionType["GiftCoin"] = 4] = "GiftCoin";
    KioskTransactionType[KioskTransactionType["EventTicket"] = 5] = "EventTicket";
    KioskTransactionType[KioskTransactionType["TrialPlay"] = 6] = "TrialPlay";
})(KioskTransactionType || (KioskTransactionType = {}));
function purge(name) {
    return !name || name.match(/^\s*$/) ? null : name;
}
export function newQuery() {
    return {
        start: today(),
        end: today().addDays(1).addSeconds(-1),
        mode: "tag",
        itemName: null,
        tag: [],
        exTag: [],
        types: [],
        id: [],
        init: 0,
    };
}
export function toIndexQuery(query) {
    let options = {
        Start: query.start.toIsoTime(),
        End: query.end.toIsoTime(),
        ItemName: purge(query.itemName),
    };
    if (query.mode == 'id') {
        options.Ids = query.id;
    }
    else {
        options.Tags = query.tag;
        options.ExTags = query.exTag;
        options.Types = query.types;
    }
    return options;
}
