/////////////////////////////////////
// 設備管理
const routes = [
    {
        path: '/item',
        component: () => import(/* webpackChunkName: "item" */ '@/views/item/Index.vue'),
        meta: {
            root: true,
        },
    },
    {
        path: '/item/edit/:mkId/:id?',
        props: true,
        component: () => import(/* webpackChunkName: "item" */ '@/views/item/Edit.vue'),
    },
    {
        path: '/item/detail/:id',
        props: true,
        component: () => import(/* webpackChunkName: "item" */ '@/views/item/Detail.vue'),
    },
];
export default routes;
