var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
let default_1 = class default_1 extends Vue {
    get attrs() {
        return Object.assign({}, this.$attrs, { id: 'ipt' + this._uid });
    }
    input(event) {
        const el = event.target;
        if (this.readonly || this.readonly == "") {
            el.checked = this.value;
            el.blur();
        }
        else {
            this.$emit('input', el.checked);
        }
    }
};
__decorate([
    Prop()
], default_1.prototype, "classes", void 0);
__decorate([
    Prop()
], default_1.prototype, "value", void 0);
__decorate([
    Prop()
], default_1.prototype, "readonly", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
