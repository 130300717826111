var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
let GoRight = class GoRight extends Vue {
    click(e) {
        if (this.to)
            this.$router.push(this.to);
        this.$emit("click", e);
    }
};
__decorate([
    Prop(Boolean)
], GoRight.prototype, "small", void 0);
__decorate([
    Prop()
], GoRight.prototype, "to", void 0);
GoRight = __decorate([
    Component
], GoRight);
export default GoRight;
