var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop } from "vue-property-decorator";
import { now, today } from "@common/date";
import { sum } from "@/views/utility";
import Base from "../Base";
import Detail from "./components/Detail.vue";
import Revenue from "./components/Revenue.vue";
import Summary from "./components/Summary.vue";
import WifiStatus from "./components/WifiStatus.vue";
import Statistics from "./components/Statistics.vue";
import { resetState } from "./Index.vue";
import { threeMonthAgo } from "./util";
export const cache = { query: null };
const COIN_TIMEOUT = 4000;
const TIMEOUT = 15000;
let Page = class Page extends Base {
    constructor() {
        super(...arguments);
        this.start = today();
        this.end = today().addDays(1).addSeconds(-1);
        this.data = null;
        this.init = null;
        this.loading = false;
    }
    get giftout() {
        if (!this.data)
            return 0;
        return sum(this.data.Daily, d => d.Giftout);
    }
    created() {
        if (this.date) {
            this.start = this.end = new Date(Date.parse(this.date));
            this.end = this.end.addDays(1).addSeconds(-1);
            this.init = 4;
        }
    }
    mounted() {
        this.load();
    }
    beforeMount() {
        // console.log(cache.query);
        if (cache.query) {
            this.start = cache.query.start;
            this.end = cache.query.end;
            this.init = cache.query.init;
        }
        cache.query = null;
    }
    beforeRouteLeave(to, from, next) {
        if (to.path.startsWith('/report/Daily')) {
            cache.query = {
                start: this.start,
                end: this.end,
                init: this.$refs.range.active,
            };
        }
        next();
    }
    get min() {
        if (!this.data)
            return new Date();
        return threeMonthAgo(this.data.MerchantKiosk.StartTime);
    }
    get statistics() {
        if (!this.data)
            return [];
        return this.data.Daily.map(item => ({
            title: item.Date.format(),
            play: item.Total,
            gift: item.Giftout,
        }));
    }
    get kiosk() {
        return this.data.MerchantKiosk.Kiosk;
    }
    daily(d) {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        this.$router.push(`/report/Daily/${this.id}/${encodeURIComponent(d.substr(0, 10).replace(/\//g, "-"))}`);
    }
    get title() {
        if (!this.data)
            return "";
        return this.data.MerchantKiosk.Name;
    }
    onchange(e) {
        if (e.length < 2)
            return;
        if (!e[1])
            e[1] = e[0];
        [this.start, this.end] = e;
        this.load();
    }
    load(background = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!background)
                this.loading = true;
            try {
                this.data = yield post("/api/Report/Detail", {
                    Id: this.id,
                    Start: this.start.toIsoTime(),
                    End: this.end.toIsoTime(),
                }, TIMEOUT);
                // 下面這一列為偵錯用
                // console.log(this.data);
                this.loading = false;
                this.update = now();
            }
            catch (e) {
                if (background)
                    return; // 背景執行模式的話忽略錯誤
                if (e instanceof Error)
                    yield this.alert(e.message || "聯繫伺服器逾時", "讀取失敗", "error");
                this.$router.back();
            }
        });
    }
    onCoin() {
        // 刷新兩次看看能不能捕捉到投幣次數更新
        this.load(true);
        setTimeout(() => this.load(true), COIN_TIMEOUT);
        resetState();
    }
};
__decorate([
    Inject()
], Page.prototype, "alert", void 0);
__decorate([
    Inject()
], Page.prototype, "confirm", void 0);
__decorate([
    Prop(String)
], Page.prototype, "id", void 0);
__decorate([
    Prop(String)
], Page.prototype, "date", void 0);
Page = __decorate([
    Component({ components: { Statistics, Detail, Revenue, Summary, WifiStatus } })
], Page);
export default Page;
