var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, ProvideReactive, Vue } from "vue-property-decorator";
import { tabKey } from "./Tab.vue";
let Tabs = class Tabs extends Vue {
    constructor() {
        super(...arguments);
        this.context = {
            tabs: [],
            index: 0,
        };
    }
    mounted() {
        if (typeof (this.value) === "number")
            this.context.index = this.value;
    }
    setIndex(i) {
        this.context.index = i;
        this.$emit("input", i);
    }
};
__decorate([
    ProvideReactive(tabKey)
], Tabs.prototype, "context", void 0);
__decorate([
    Prop()
], Tabs.prototype, "value", void 0);
Tabs = __decorate([
    Component
], Tabs);
export default Tabs;
