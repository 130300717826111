var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable */
import { Component, Prop, Vue } from "vue-property-decorator";
import * as bootstrap from 'bootstrap';
let DateSelect = class DateSelect extends Vue {
    constructor() {
        super(...arguments);
        this.selectValue = '';
        this.wheels = [
            {
                type: 'year',
                translateY: 0,
                anim: false,
                transitionTime: '700ms',
                data: []
            },
            {
                type: 'month',
                translateY: 0,
                anim: false,
                transitionTime: '700ms',
                data: []
            },
            {
                type: 'day',
                translateY: 0,
                anim: false,
                transitionTime: '700ms',
                data: []
            }
        ];
        this.startY = 0;
        this.moveY = 0;
        this.oldMoveY = 0;
        this.moveEndY = 0;
        this.offsetDistance = 0;
        this.offset = 0;
        this.oversizeBorder = 0;
        this.startTime = 0;
        this.liHeight = 40;
        this.minDate = '';
        this.maxDate = '';
        this.initDate = '';
        this.clickFlag = false;
        this.activeClick = {
            wheelIndex: -100,
            itemIndex: -100,
        };
        this.activeWheelIndex = 0;
        this.windowHeight = document.documentElement.clientHeight || 300;
    }
    mounted() {
        this.modal = new bootstrap.Modal(this.$refs.mdl);
        this.selectValue = this.value;
        this.initSetting();
        this.initOption();
        this.initListener();
    }
    pad(n) {
        return (n < 10 ? '0' : '') + n;
    }
    getOpacity(wheelIndex, itemIndex) {
        return wheelIndex == this.activeClick.wheelIndex && itemIndex == this.activeClick.itemIndex ? 1 : 0;
    }
    initListener() {
        var _this = this;
        window.addEventListener("resize", function () {
            _this.windowHeight = document.documentElement.clientHeight || 300;
        }, false);
    }
    initSetting() {
        let def = this.init;
        let max = new Date(Date.parse(this.max));
        let min = new Date(Date.parse(this.min));
        if (def > max)
            def = max;
        if (def < min)
            def = min;
        this.initDate = this.value && this.checkIsFormatStr(this.value) ? this.value : this.getDateStr(def);
        this.maxDate = this.max && this.checkIsFormatStr(this.max) ? this.max : this.getDateStr(def, 2);
        this.minDate = this.min && this.checkIsFormatStr(this.min) ? this.min : this.getDateStr(def, -10);
    }
    initOption() {
        var maxDateObj = this.getDateStrObj(this.maxDate);
        var minDateObj = this.getDateStrObj(this.minDate);
        var initDateObj = this.getDateStrObj(this.initDate);
        for (var i = minDateObj.year; i <= maxDateObj.year; i++) {
            this.wheels[0].data.push(i);
        }
        for (var j = 1; j <= 12; j++) {
            this.wheels[1].data.push(j);
        }
        for (var k = 1; k <= this.calcDays(initDateObj.year, initDateObj.month); k++) {
            this.wheels[2].data.push(k);
        }
        this.locateWheelByVal(this.initDate);
    }
    locateWheelByVal(dateString) {
        var minDateObj = this.getDateStrObj(this.minDate);
        var dateObj = this.getDateStrObj(dateString);
        // 定位显示初始值
        this.wheels[0].translateY = this.getDistanceByIndex(dateObj.year - minDateObj.year);
        this.wheels[1].translateY = this.getDistanceByIndex(dateObj.month - 1);
        this.wheels[2].translateY = this.getDistanceByIndex(dateObj.day - 1);
    }
    getDateStrObj(dateString) {
        // 特殊情况的处理
        var tempArr = dateString.split('-');
        return {
            year: ~~tempArr[0],
            month: ~~tempArr[1],
            day: ~~tempArr[2]
        };
    }
    addPrefix(num) {
        return num < 10 ? '0' + num : num;
    }
    getDateStr(dateObj, offsetYear, offsetMonth, offsetDay) {
        var tempArr = [];
        tempArr.push(dateObj.getFullYear() + (offsetYear || 0));
        tempArr.push(this.addPrefix(dateObj.getMonth() + 1 + (offsetMonth || 0)));
        tempArr.push(this.addPrefix(dateObj.getDate() + (offsetDay || 0)));
        return tempArr.join('-');
    }
    checkIsFormatStr(dateString) {
        if (dateString && typeof (dateString) == 'string') {
            var tempArr = dateString.split('-');
            if (tempArr.length > 2) {
                var year = ~~tempArr[0], month = ~~tempArr[1], day = ~~tempArr[2];
                if ((year > 0 && year < 10000) && (month >= 1 && month <= 12) && (day >= 1 && day <= this.calcDays(year, month))) {
                    return true;
                }
            }
        }
        console.warn('日期"' + dateString + '"不符合"yyyy-mm-dd格式"，或不存该日期，已替换为默认值。');
        return false;
    }
    getDistanceByIndex(index) {
        return (2 - index) * this.liHeight;
    }
    getIndexByDistance(translateY) {
        return Math.floor(-translateY / this.liHeight) + 2;
    }
    getWheelData(wheelIndex) {
        var dataIndex = this.getIndexByDistance(this.wheels[wheelIndex].translateY);
        dataIndex = dataIndex < 0 ? 0 : dataIndex;
        dataIndex = dataIndex >= this.wheels[wheelIndex].data.length ? this.wheels[wheelIndex].data.length - 1 : dataIndex;
        return this.wheels[wheelIndex].data[dataIndex];
    }
    //求月份最大天数
    calcDays(year, month) {
        // 传进来的month值是已经加1的，而下面的技巧中刚好Month也需要+1
        return new Date(year, month, 0).getDate();
    }
    fixPosition(distance) {
        //修正位置
        return Math.round(distance / this.liHeight) * this.liHeight;
    }
    checkIsOverBorder(curWheelObj) {
        //反弹
        var _this = this;
        this.oversizeBorder = -(curWheelObj.data.length - 3) * this.liHeight;
        if (curWheelObj.translateY > 2 * this.liHeight) {
            setTimeout(function () {
                curWheelObj.transitionTime = '700ms';
                curWheelObj.translateY = 2 * _this.liHeight; // 需要增加动画
            }, 100);
        }
        else if (curWheelObj.translateY < this.oversizeBorder) {
            setTimeout(function () {
                curWheelObj.transitionTime = '700ms';
                curWheelObj.translateY = _this.oversizeBorder;
            }, 100);
        }
    }
    updateDays() {
        var newMonthDaysNum = this.calcDays(this.getWheelData(0), this.getWheelData(1));
        if (newMonthDaysNum > 0 && this.wheels[2].data.length != newMonthDaysNum) {
            var tempArr = [];
            for (var k = 1; k <= newMonthDaysNum; k++) {
                tempArr.push(k);
            }
            this.wheels[2].data = tempArr;
            this.checkIsOverBorder(this.wheels[2]);
        }
    }
    handleTouch(e, index) {
        e = e || window.event;
        var curWheelObj = typeof (index) == 'number' ? this.wheels[index] : this.wheels[this.activeWheelIndex];
        switch (e.type) {
            case 'touchstart':
            case 'mousedown':
                if (e.type == 'touchstart') {
                    this.startY = e.touches[0].clientY;
                }
                else {
                    // mousedown
                    this.startY = e.clientY;
                    this.activeWheelIndex = index;
                    this.clickFlag = true;
                    e.preventDefault();
                }
                curWheelObj.anim = false;
                this.oldMoveY = this.startY;
                this.startTime = (new Date()).getTime();
                break;
            case 'touchend':
            case 'mouseup':
                if (e.type == 'touchend') {
                    this.moveEndY = e.changedTouches[0].clientY;
                }
                else {
                    // mousedown
                    this.moveEndY = e.clientY;
                    this.clickFlag = false;
                }
                this.offsetDistance = this.moveEndY - this.startY;
                curWheelObj.anim = true;
                curWheelObj.translateY = this.fixPosition(curWheelObj.translateY + this.offset);
                var offsetTime = (new Date()).getTime() - this.startTime;
                var scrollSpeed = this.offsetDistance / offsetTime;
                var tempTime = Math.abs(Math.floor(scrollSpeed * 1000));
                curWheelObj.transitionTime = '700ms';
                if (Math.abs(scrollSpeed) > 0.3) {
                    curWheelObj.transitionTime = tempTime > 700 ? (tempTime + 'ms') : '700ms';
                    curWheelObj.translateY = this.fixPosition(curWheelObj.translateY + scrollSpeed * 250);
                }
                this.checkIsOverBorder(curWheelObj);
                this.clearHover();
                break;
            case 'mousemove':
            case 'touchmove':
                e.preventDefault();
                if (e.type == 'mousemove' && !this.clickFlag) {
                    return false;
                }
                this.moveY = e.type == 'touchmove' ? e.touches[0].clientY : e.clientY;
                this.offset = this.moveY - this.oldMoveY;
                curWheelObj.translateY += this.offset;
                this.oldMoveY = this.moveY;
                break;
            case 'mousewheel':
                curWheelObj.anim = true;
                curWheelObj.translateY = this.fixPosition(curWheelObj.translateY + Math.floor((e.wheelDelta || e.detail) * 0.3));
                this.oversizeBorder = -(curWheelObj.data.length - 3) * this.liHeight;
                this.checkIsOverBorder(curWheelObj);
                break;
        }
        // 切换年月的时候，更新天数
        if (index <= 1) {
            this.updateDays();
        }
    }
    handleSingleClick(e, wheelIndex, itemIndex) {
        if (Math.abs(this.offsetDistance) < 30) {
            this.wheels[wheelIndex].translateY = this.getDistanceByIndex(itemIndex);
        }
    }
    hoverClass(e, wheelIndex, itemIndex) {
        this.activeClick.wheelIndex = wheelIndex;
        this.activeClick.itemIndex = itemIndex;
    }
    clearHover() {
        this.activeClick.wheelIndex = -100;
        this.activeClick.itemIndex = -100;
    }
    getSelectData() {
        var _this = this;
        var tempArr = [];
        this.wheels.forEach(function (item, wheelIndex) {
            tempArr.push(_this.addPrefix(_this.getWheelData(wheelIndex)));
        });
        this.selectValue = tempArr.join('-');
        this.$emit('input', this.selectValue);
        this.hidePanel();
    }
    hidePanel() {
        this.clearHover();
        this.modal.hide();
        // 关闭后 复位已选中的值
        if (this.selectValue) {
            this.locateWheelByVal(this.selectValue);
        }
    }
    showPanel() {
        if (this.$attrs.disabled || this.$attrs.readonly)
            return;
        this.clearHover();
        this.modal.show();
    }
};
__decorate([
    Prop({ type: String, default: '' })
], DateSelect.prototype, "value", void 0);
__decorate([
    Prop({ type: String, default: '' })
], DateSelect.prototype, "min", void 0);
__decorate([
    Prop({ type: String, default: '' })
], DateSelect.prototype, "max", void 0);
__decorate([
    Prop({ type: String, default: '' })
], DateSelect.prototype, "placeholder", void 0);
__decorate([
    Prop({ type: [String, Object, Array], default: '' })
], DateSelect.prototype, "inputClass", void 0);
__decorate([
    Prop({ type: [String, Object, Array], default: '' })
], DateSelect.prototype, "inputStyle", void 0);
__decorate([
    Prop({ default: () => new Date() })
], DateSelect.prototype, "init", void 0);
DateSelect = __decorate([
    Component
], DateSelect);
export default DateSelect;
