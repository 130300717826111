/* eslint-disable max-lines */
import { today } from "@common/date";
import type { ContactModel, LeapAppMerchantModel, OutletModel } from "@common/models/merchant";
import type { KioskBaseModel, MerchantKioskBaseModel } from "@common/models/kiosk";

interface OutletBaseModel {
	Id: string;
	Name: string;
	Contact: ContactModel;
}

export interface PromotionSummaryBase {
	TodayUsage: number;
	PromotionCount: number;
	TerminalCount: number;
}

export interface TerminalPromotionModel {
	Id: string;
	KioskId: string;
	PromotionId: string;
	Name: string;
	CodeName: string;
	Status: TerminalPromotionStatus;
}

export interface PromotionSummary extends PromotionSummaryBase {
	CodeName: string;
	Name: string;
}

export interface OwnerIndexModel {
	Merchant: LeapAppMerchantModel;
	SubAccounts: LeapAppMerchantModel[];
	Promotions: PromotionSummary[];
}

export interface OwnerOutletModel extends OutletBaseModel {
	MerchantName: string;
	Description: string;
	LeapaLink: string;
	Hero: string;
	Logo: string;
	Advertisement: string[];
	UseGPS: boolean;
	Terminals: OutletTerminalModel[];
}

export interface PromotionIndexModel {
	Outlets: PromotionOutletModel[];
}

export interface PromotionOutletModel extends OutletBaseModel {
	IsDisabled: boolean;
	Summary: PromotionSummaryBase;
}

export interface PromotionTerminalIndexModel {
	MerchantId: string;
	OutletName: string;
	Terminals: OutletTerminalModel[];
}

export interface OutletTerminalModel {
	Id: string;
	Name: string;
	CreateTime: Date;
	MerchantKiosk: MerchantKioskBaseModel;
	Kiosk: KioskBaseModel;
	Owner: string;
	Status: TerminalPromotionStatus | null;
}

export enum TerminalPromotionStatus {
	None = 0,
	Inviting = 1,
	Activated = 2,
	Deactivated = -1,
}

export interface PromotionStatisticsModel<T> extends PromotionModel<T> {
	Statistics: PromotionStatistics;
}

export interface PromotionStatistics {
	Date: Date;
	WriteOffCount: number;
	WriteOffUserCount: number;
	ClaimCount: number;
	ClaimUserCount: number;
	UsedCount: number;
	TotalAmount: number;
	UnusedCount: number;
	ExpireCount: number;
}

export interface PromotionStatisticsIndexModel<T> {
	Daily: PromotionStatistics[];
	Promotions: PromotionStatisticsModel<T>[];
}

export interface PromotionModel<T = unknown> {
	Id: string;
	OutletId: string;
	CodeName: string;
	IsActive: boolean;
	IsRedeemActive: boolean;
	Name: string;
	Remark: string;
	readonly AdminName: string;
	StartTime: Date;
	EndTime: Date;
	Settings: T;
	Status: PromotionStatus;
	RedeemStatus: PromotionStatus;
	CreateTime: Date;
	HasShareGiveaway: boolean;
	ShareGiveawayId: string;
}

export interface PromotionDetailModel<T> extends PromotionModel<T> {
	Link: string;
	Remain: number;
	RemainCount: number;
	ReceiveRecords: PromotionStatistics[];
}

export interface BaseGiveawayModel {
	/** @deprecated */
	ActivationCount: number | null;
	/** @deprecated */
	IssuanceLimit: number | null;

	Type: EventGiveawayType;
	ReceiveLimit: number | null;
	StartTime: Date | null;
	EndTime: Date | null;
	FixedActivations: Activation[] | null;
	RandomActivation: RandomActivation | null;
	RandomActivations: Activation[];
	DistributerActivation: number | null;
	DisplayCount: boolean;
	ExpireDays: number | null;
	InitDays: number | null;
	ExpireHours: number | null;
	InitHours: number | null;

	MinActivationCount: number;
	MaxActivationCount: number;
	MixedDistribution: boolean;

	_range: Date[];
}

export interface PointGiveawayModel extends BaseGiveawayModel {
	ItemId: string;
	Item: PointGiveawayPointModel;
}

export interface Activation {
	Amount: number;
	Count: number;
}

export interface RandomActivation {
	TotalCount: number;
	MinCount: number;
	MaxCount: number;
}

export enum EventGiveawayType {
	Ticket = 1,
	Link = 2,
	Distributer = 3,
}

export function giveawayTypeToString(type: EventGiveawayType): string {
	switch(type) {
		case EventGiveawayType.Link: return "兌換連結條碼";
		case EventGiveawayType.Ticket: return "紙本兌換券";
		case EventGiveawayType.Distributer: return "專人發送";
		default: return "不明類別";
	}
}

export enum PromotionStatus {
	Active,
	Unstarted,
	Inactive,
	Expire,
}

export interface PointGiveawayPointBaseModel {
	Id: string;
	Name: string;
	Settings: PointGiveawayPointSettings;
}

export interface PointGiveawayPointModel extends PointGiveawayPointBaseModel {
	OutletIds: string[];
	Outlets: OutletModel[];
	OutletOptions: OutletModel[];
}

export interface PointGiveawayPointSettings {
	ForIchibankuji: boolean;
	RequirePin: boolean;
	IsHidden: boolean;
	ExtendDays?: number;
}

export interface RedepmtionStaffModel {
	Id: string;
	Name: string;
	AccountId: string;
	OutletIds: string[];
	Outlets: OutletModel[];
	Username: string;
	OutletOptions: OutletModel[];
}

export interface ShareGiveawayModel {
	TargetId: string;
	RewardCount: number;
	ShareLimit: number | null;
	RewardType: ShareGiveawayRewardType;
	ExpireDays: number;
	TargetPromotion: PromotionModel;
	EditDisabled: boolean;
}

export enum ShareGiveawayRewardType {
	AfterUse = 0,
	AfterReceive = 1,
}

export interface PromotionRecordQuery {
	Start: Date;
	End: Date;
	Includes: string[];
	Excludes: string[];

	init: number; // 前端用
}

export function newQuery(): PromotionRecordQuery {
	return {
		Start: today(),
		End: today().addDays(1).addSeconds(-1),
		Includes: [],
		Excludes: [],
		init: 0,
	};
}
