var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Prop, Vue } from "vue-property-decorator";
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-tw';
import DatePicker from 'vue2-datepicker';
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.showPassword = false;
    }
    valid() {
        return __awaiter(this, void 0, void 0, function* () {
            let provider = this.$refs.provider;
            let result = yield provider.validate();
            return result.valid;
        });
    }
    get showPlaceholder() {
        return this.type == 'select' && Boolean(this.placeholder && !this.value && this.value !== 0);
    }
    get required() {
        if (typeof this.rules == "string")
            return this.rules.includes('required');
        if (typeof this.rules == "object")
            return 'required' in this.rules;
        return false;
    }
    get formateDate() {
        var _a;
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        if (this.value instanceof Date)
            return this.value.toISOString().substr(0, 10);
        if (this.value instanceof String)
            return (_a = this.value.replace(/-/g, ".")) !== null && _a !== void 0 ? _a : "";
        return "";
    }
    get formatTime() {
        var _a;
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        if (this.value instanceof Date)
            return new Date(this.value.getTime() + 8 * 60 * 60 * 1000).toISOString().substr(0, 16);
        if (this.value instanceof String)
            return (_a = this.value.replace(/-/g, ".")) !== null && _a !== void 0 ? _a : "";
        return "";
    }
    dateChange(d) {
        let date = Date.parse(d);
        if (!isNaN(date))
            this.$emit('input', new Date(date));
        else
            this.$emit('input', null);
    }
    get renderType() {
        return this.showPassword ? "text" : this.type;
    }
    get hasAppend() {
        return Boolean(this.$scopedSlots.append);
    }
    onInput(event) {
        var target = event.target;
        var value = target.value;
        if (this.renderType == 'number') {
            // iOS 上不會擋輸入文字，這邊加以處理
            var num = Number(value);
            if (isNaN(num)) {
                value = String(this.value);
                target.value = value;
            }
            else {
                value = num;
            }
        }
        this.$emit('input', value);
    }
};
__decorate([
    Prop(String)
], default_1.prototype, "vid", void 0);
__decorate([
    Prop(String)
], default_1.prototype, "icon", void 0);
__decorate([
    Prop()
], default_1.prototype, "rules", void 0);
__decorate([
    Prop(String)
], default_1.prototype, "min", void 0);
__decorate([
    Prop(String)
], default_1.prototype, "max", void 0);
__decorate([
    Prop(Date)
], default_1.prototype, "init", void 0);
__decorate([
    Prop(String)
], default_1.prototype, "placeholder", void 0);
__decorate([
    Prop(Function)
], default_1.prototype, "disabledDate", void 0);
__decorate([
    Prop({ default: 'text' })
], default_1.prototype, "type", void 0);
__decorate([
    Prop()
], default_1.prototype, "value", void 0);
__decorate([
    Prop(Boolean)
], default_1.prototype, "small", void 0);
__decorate([
    Prop(String)
], default_1.prototype, "inputClass", void 0);
default_1 = __decorate([
    Component({
        components: { DatePicker },
    })
], default_1);
export default default_1;
