import Vue from 'vue';
// 註冊全域元件
const requireComponent = require.context('.', true, /\.vue$/);
requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName);
    const config = componentConfig.default || componentConfig;
    // config.name 在 release 建置中會被 mangle，所以只能以檔名為準
    const name = fileName.split('/').pop().replace(/\.vue$/, '');
    Vue.component(name, config);
});
