var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Component, Vue, Watch } from "vue-property-decorator";
import { now } from "@common/date";
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.time = null;
        this.update = now();
        this.inte = 0;
    }
    created() {
        this.inte = window.setInterval(() => this.updateTime(), 1000);
    }
    beforeDestroy() {
        clearInterval(this.inte);
    }
    updateTime() {
        this.time = this.getTime();
    }
    getTime() {
        let diff = (now().getTime() - this.update.getTime()) / 1000;
        if (diff < 60)
            return "剛剛";
        diff /= 60;
        if (diff < 60)
            return Math.floor(diff) + " 分鐘前";
        diff /= 60;
        if (diff < 24)
            return Math.floor(diff) + " 小時前";
        return Math.floor(diff / 24) + " 天前";
    }
};
__decorate([
    Watch("update")
], default_1.prototype, "updateTime", null);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
