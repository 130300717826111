var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import ScrollMain from "./ScrollMain.vue";
let NavBarLayout = class NavBarLayout extends Vue {
    constructor() {
        super(...arguments);
        this.navClass = "";
    }
    created() {
        this.$store.commit("setContainerClass", this.containerClass);
        this.$store.commit("setHideMenu", this.hideMenu);
    }
    mounted() {
        this.setup(0);
    }
    tryBack() {
        if (this.backUrl)
            this.$router.push(this.backUrl);
        else
            this.back();
    }
    setup(scrollTop) {
        let c = "";
        if (scrollTop > 1)
            c += " nav-shadow";
        this.toggleNavTitle(scrollTop > 0);
        this.navClass = c;
    }
    toggleNavTitle(show) {
        var _a;
        (_a = this.$refs.title) === null || _a === void 0 ? void 0 : _a.classList.toggle('show', show);
    }
};
__decorate([
    Prop({ default: '' })
], NavBarLayout.prototype, "containerClass", void 0);
__decorate([
    Prop({ default: '' })
], NavBarLayout.prototype, "mainClass", void 0);
__decorate([
    Prop({ default: '' })
], NavBarLayout.prototype, "navigationClass", void 0);
__decorate([
    Prop({ default: '' })
], NavBarLayout.prototype, "title", void 0);
__decorate([
    Prop(String)
], NavBarLayout.prototype, "backUrl", void 0);
__decorate([
    Prop(Boolean)
], NavBarLayout.prototype, "hideNav", void 0);
__decorate([
    Prop(Boolean)
], NavBarLayout.prototype, "hideMenu", void 0);
__decorate([
    Prop()
], NavBarLayout.prototype, "pull", void 0);
__decorate([
    Inject()
], NavBarLayout.prototype, "back", void 0);
__decorate([
    Inject()
], NavBarLayout.prototype, "toggleMenu", void 0);
NavBarLayout = __decorate([
    Component({ components: { ScrollMain } })
], NavBarLayout);
export default NavBarLayout;
