var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Provide, Vue } from "vue-property-decorator";
import { isLegacyAndroid } from "@common/jsInterface";
let App = class App extends Vue {
    created() {
        const obp = MobileApp.onBackPress;
        MobileApp.onBackPress = () => this.onBackPress() || obp();
    }
    mounted() {
        if (isLegacyAndroid()) {
            this.alert("LeaPA App 已經發佈了新版的應用程式，舊的版本將停止更新，敬請更新至新的版本。", "請安裝新版 App", "warning", "前往 Google Play 安裝")
                .then(() => window.open("https://play.google.com/store/apps/details?id=com.leapa.android.merchant"));
        }
        this.$refs.menu.init(this.$refs.panel);
    }
    get version() {
        return VERSION;
    }
    alert(message, title, icon, btn) {
        return this.$refs.alert.showIcon(message, title, icon, btn);
    }
    confirm(message, title, icon) {
        return this.$refs.confirm.showIcon(message, title, icon);
    }
    toggleMenu() {
        this.$refs.menu.toggle();
    }
    back() {
        var _a;
        const DELAY = 10;
        if ((_a = this.$router.currentRoute.meta) === null || _a === void 0 ? void 0 : _a.root)
            return false;
        let href = location.href;
        this.$router.back();
        setTimeout(() => {
            // 如果延遲之後網址沒有改變，就表示歷史無法繼續返回，此時就直接跳首頁
            if (location.href == href)
                this.$router.replace('/');
        }, DELAY);
        return true;
    }
    onBackPress() {
        if (this.$refs.menu.closeIfOpen())
            return true;
        return this.back();
    }
    afterLeave() {
        window.dispatchEvent(new Event('routertransition'));
    }
};
__decorate([
    Provide()
], App.prototype, "alert", null);
__decorate([
    Provide()
], App.prototype, "confirm", null);
__decorate([
    Provide()
], App.prototype, "toggleMenu", null);
__decorate([
    Provide()
], App.prototype, "back", null);
App = __decorate([
    Component
], App);
export default App;
