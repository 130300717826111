/////////////////////////////////////
// 發送 / 核銷
const routes = [
    {
        path: '/reception',
        component: () => import(/* webpackChunkName: "reception" */ '@/views/reception/Index.vue'),
        meta: {
            root: true,
        },
    },
    {
        path: '/reception/prepareSend/:id/:codename',
        props: true,
        component: () => import(/* webpackChunkName: "reception" */ '@/views/reception/PrepareSend.vue'),
    },
    {
        path: '/reception/send/:id',
        props: true,
        component: () => import(/* webpackChunkName: "reception" */ '@/views/reception/Send.vue'),
    },
    {
        path: '/reception/nfc',
        component: () => import(/* webpackChunkName: "reception" */ '@/views/reception/NFC.vue'),
    },
    {
        path: '/reception/card/:id',
        props: true,
        component: () => import(/* webpackChunkName: "reception" */ '@/views/reception/Card.vue'),
    },
    {
        path: '/reception/scan/:id',
        props: true,
        component: () => import(/* webpackChunkName: "reception" */ '@/views/reception/Scan.vue'),
    },
    {
        path: '/reception/lookup/:id',
        props: true,
        component: () => import(/* webpackChunkName: "reception" */ '@/views/reception/Lookup.vue'),
    },
    {
        path: '/reception/ticket/:id/:code/:ticketId',
        props: true,
        component: () => import(/* webpackChunkName: "reception" */ '@/views/reception/Ticket.vue'),
    },
    {
        path: '/reception/outlet/:userId/:outletId',
        props: true,
        component: () => import(/* webpackChunkName: "reception" */ '@/views/reception/outlet/Index.vue'),
    },
    {
        path: '/reception/outlet/ticket/:userId/:outletId',
        props: true,
        component: () => import(/* webpackChunkName: "reception" */ '@/views/reception/outlet/Ticket.vue'),
    },
    {
        path: '/reception/outlet/point/:userId/:pointId',
        props: true,
        component: () => import(/* webpackChunkName: "reception" */ '@/views/reception/outlet/Point.vue'),
    },
];
export default routes;
