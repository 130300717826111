var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Vue from 'vue';
import VueRouter from 'vue-router';
import { OK, refresh, TEN_SECONDS, settings } from "@common/communication";
import { anyOpen } from '@common/components/dialog/dialog';
import Error from '@/views/Error.vue';
import Term from '@/views/Terms.vue';
import store from '@/store';
import { setScrollState } from '@/views/setScroll';
import merchantRoutes from './merchant';
import fundRoutes from './fund';
import deviceRoutes from './device';
import loginRoutes from './login';
import reportRoutes from './report';
import itemRoutes from './item';
import shareRoutes from './share';
import serviceRoutes from './service';
import ownerRoutes from './owner';
import infoRoutes from './info';
import receptionRoutes from './reception';
Vue.use(VueRouter);
const routes = [
    /////////////////////////////////////
    // 主要
    {
        path: '*',
        component: Error,
        name: '404',
        meta: {
            anonymous: true,
        },
    },
    {
        path: '/',
        redirect: '/report',
    },
    {
        path: '/terms',
        component: Term,
        meta: {
            anonymous: true,
            root: true,
        },
    },
    ...loginRoutes,
    ...reportRoutes,
    ...merchantRoutes,
    ...fundRoutes,
    ...deviceRoutes,
    ...itemRoutes,
    ...shareRoutes,
    ...serviceRoutes,
    ...ownerRoutes,
    ...infoRoutes,
    ...receptionRoutes,
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (!savedPosition)
            return { x: 0, y: 0 };
        return savedPosition;
    },
});
router.beforeEach((to, from, next) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    clearTimeout(setScrollState.timeout);
    if (anyOpen())
        return; // 有靜態對話方塊開啟的時候不准跳轉頁面
    // 否則的話在跳轉之前記得要去除掉 Modal 陰影（Modal 本身會因為跳轉直接消滅）
    for (const backdrop of document.querySelectorAll(".modal-backdrop")) {
        if (backdrop.parentNode)
            backdrop.parentNode.removeChild(backdrop);
    }
    if (store.state.accessToken == null && !((_a = to.meta) === null || _a === void 0 ? void 0 : _a.anonymous)) {
        // 如果有 refreshToken，嘗試用它登入看看
        let token = localStorage.getItem("token");
        if (token) {
            try {
                let response = yield refresh(token, TEN_SECONDS); // 這邊的時間設久一點
                if (response.status == OK) {
                    next();
                    return;
                }
                else {
                    console.log("Auth 伺服器傳回錯誤 " + (yield response.text())); // 偵錯用
                    localStorage.removeItem("token");
                }
            }
            catch (e) {
                console.log("刷新 Timeout"); // 偵錯用
            }
        }
        // 如果沒有登入而且頁面不允許匿名，則跳轉至登入頁
        next('/login?callback=' + encodeURI(to.path));
    }
    else {
        next();
    }
}));
let firstRoute = true;
Object.defineProperty(window, 'pageXOffset', {
    get() { var _a, _b; return (_b = (_a = document.querySelector('main')) === null || _a === void 0 ? void 0 : _a.scrollLeft) !== null && _b !== void 0 ? _b : 0; },
});
Object.defineProperty(window, 'pageYOffset', {
    get() { var _a, _b; return (_b = (_a = document.querySelector('main')) === null || _a === void 0 ? void 0 : _a.scrollTop) !== null && _b !== void 0 ? _b : 0; },
});
Object.defineProperty(window, 'scrollTo', {
    value: (option) => {
        let els = document.querySelectorAll('main');
        let el = els[els.length - 1];
        el === null || el === void 0 ? void 0 : el.scrollTo(option.left, option.top);
    },
});
router.beforeResolve((to, from, next) => {
    var _a, _b;
    let hideBack = (_b = (_a = to.meta) === null || _a === void 0 ? void 0 : _a.root) !== null && _b !== void 0 ? _b : false;
    let setup = () => {
        store.commit("setHideBack", hideBack);
    };
    if (firstRoute) {
        setup();
        firstRoute = false;
    }
    else {
        window.addEventListener('routertransition', setup, { once: true });
    }
    next();
});
settings.login = () => router.replace('/login');
export default router;
