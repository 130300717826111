var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import * as bootstrap from 'bootstrap';
import Teleport from 'vue2-teleport';
import CheckboxBase from '../CheckboxBase.vue';
import Calender from './Calender.vue';
function isZero(d) {
    if (!d)
        return true;
    return d.getHours() == 0 && d.getMinutes() == 0 && d.getSeconds() == 0;
}
let DateTimeRangePicker = class DateTimeRangePicker extends Vue {
    constructor() {
        super(...arguments);
        this.open = false;
        this.time = false;
        this.ok = false;
    }
    onTimeModeChanged(v) {
        var _a, _b;
        if (v)
            return;
        this.value[0] = (_a = this.value[0]) === null || _a === void 0 ? void 0 : _a.datePart();
        this.value[1] = (_b = this.value[1]) === null || _b === void 0 ? void 0 : _b.datePart().addDays(1).addSeconds(-1);
    }
    mounted() {
        this.modal = new bootstrap.Modal(this.$refs.el, { backdrop: 'static' });
    }
    show() {
        var _a;
        this.open = true;
        this.ok = false;
        if (!isZero(this.value[0]) || !isZero((_a = this.value[1]) === null || _a === void 0 ? void 0 : _a.addSeconds(1)))
            this.time = true;
        return new Promise(resolve => {
            this.$refs.el.addEventListener('hide.bs.modal', () => resolve(this.ok), { once: true });
            this.modal.show();
        });
    }
    enter() {
        this.open = false;
        this.ok = true;
        this.modal.hide();
    }
};
__decorate([
    Prop()
], DateTimeRangePicker.prototype, "value", void 0);
__decorate([
    Prop()
], DateTimeRangePicker.prototype, "filter", void 0);
__decorate([
    Prop(Boolean)
], DateTimeRangePicker.prototype, "hideTime", void 0);
__decorate([
    Watch('time')
], DateTimeRangePicker.prototype, "onTimeModeChanged", null);
DateTimeRangePicker = __decorate([
    Component({ components: { Teleport, Calender, CheckboxBase } })
], DateTimeRangePicker);
export default DateTimeRangePicker;
