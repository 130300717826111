var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import Draggable from 'vuedraggable';
import ImageUploader from "./ImageUploader.vue";
import UploadImage from "./UploadImage.vue";
let _pictureUploaderId = 0;
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.pid = _pictureUploaderId++;
        this.dragging = false;
        this.wait = false; // 控制動畫顯示
    }
    get readonly() {
        return this.disabled || Boolean(this.$attrs.readonly);
    }
    add(data) {
        this.value.push(data);
        this.$emit('input', this.value.concat());
    }
    del(i) {
        this.value.splice(i, 1);
        this.$emit('input', this.value.concat());
    }
};
__decorate([
    Prop({
        type: Number,
        default: Number.POSITIVE_INFINITY,
    })
], default_1.prototype, "max", void 0);
__decorate([
    Prop(Number)
], default_1.prototype, "size", void 0);
__decorate([
    Prop(Boolean)
], default_1.prototype, "disabled", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => [],
    })
], default_1.prototype, "value", void 0);
default_1 = __decorate([
    Component({ components: { Draggable, ImageUploader, UploadImage } })
], default_1);
export default default_1;
