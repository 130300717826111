var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop } from "vue-property-decorator";
import LayoutBase from "@common/base/LayoutBase";
import NavBarLayout from "@common/base/NavBarLayout.vue";
let LoginLayout = class LoginLayout extends LayoutBase {
};
__decorate([
    Prop({ default: 'col py-3 px-4' })
], LoginLayout.prototype, "headerClass", void 0);
__decorate([
    Prop(Boolean)
], LoginLayout.prototype, "hideNav", void 0);
__decorate([
    Prop(String)
], LoginLayout.prototype, "backUrl", void 0);
LoginLayout = __decorate([
    Component({
        components: { NavBarLayout },
    })
], LoginLayout);
export default LoginLayout;
