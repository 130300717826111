var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Prop, Vue } from "vue-property-decorator";
import dialogs from "@common/dialogs";
function convertHeic(blob) {
    return __awaiter(this, void 0, void 0, function* () {
        // 動態載入程式庫
        const heic2any = (yield import(/* webpackChunkName: "heic" */ 'heic2any')).default;
        return yield heic2any({
            blob,
            toType: 'image/jpeg',
            quality: 0.5, // 可以合理假定 iPhone 拍的照片都很高解析度
        });
    });
}
function tryResize(blob, size) {
    return __awaiter(this, void 0, void 0, function* () {
        const image = new Image();
        const url = URL.createObjectURL(blob);
        try {
            yield new Promise((resolve, reject) => {
                image.onload = resolve;
                image.onerror = reject;
                image.src = url;
            });
            const canvas = document.createElement('canvas');
            let width = image.width, height = image.height;
            const max = Math.max(width, height);
            if (size === undefined || max < size)
                return blob;
            width *= size / max;
            height *= size / max;
            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            return new Promise(resolve => {
                canvas.toBlob(resolve, 'image/jpeg');
            });
        }
        finally {
            URL.revokeObjectURL(url);
        }
    });
}
let default_1 = class default_1 extends Vue {
    add() {
        return __awaiter(this, void 0, void 0, function* () {
            const files = this.$refs.file.files;
            if (files === null || files === void 0 ? void 0 : files.length) {
                try {
                    const name = files[0].name;
                    this.$emit('start');
                    let blob = files[0];
                    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                    const test = yield blob.slice(0, 16).text();
                    if (test.includes("ftyphe") || name.toLowerCase().endsWith('heic'))
                        blob = yield convertHeic(blob);
                    blob = yield tryResize(blob, this.size);
                    // 讀取檔案
                    const result = yield new Promise(resolve => {
                        const reader = new FileReader();
                        reader.onload = e => resolve(e.target.result);
                        reader.readAsDataURL(blob);
                    });
                    // 處理檔案
                    const url = result.split(',');
                    const data = {
                        Name: name,
                        Data: url[1],
                        Header: url[0],
                    };
                    this.$emit('input', data);
                }
                catch (e) {
                    yield dialogs.alert("不支援的圖片格式", undefined, 'error');
                }
                finally {
                    this.$refs.file.value = '';
                    this.$emit('end');
                }
            }
        });
    }
};
__decorate([
    Prop(Number)
], default_1.prototype, "size", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
