var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { login } from "./Login";
const LOGIN_TIMEOUT = 8000;
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.username = "";
        this.password = "";
        this.remember = false;
    }
    beforeMount() {
        let un = localStorage.getItem("username");
        if (un) {
            this.remember = true;
            this.username = un;
        }
    }
    send() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield post("/api/login", {
                    Username: this.username,
                    Password: this.password,
                }, LOGIN_TIMEOUT);
                login(result, this.remember ? this.username : null);
                this.$router.replace((_a = this.callback) !== null && _a !== void 0 ? _a : "/");
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }
            catch (err) {
                if (err.message == '尚未註冊') {
                    yield this.alert('請先進行註冊再登入', '尚未註冊', 'warning');
                    this.$router.push('/login/register/' + this.username);
                    return;
                }
                if (err.message == '帳號鎖定') {
                    yield this.alert('密碼已被鎖定，\n請前往忘記密碼重新設定。', '帳號鎖定', 'warning');
                    return;
                }
                if (err.message == '登入無效')
                    err.icon = 'error';
                throw err;
            }
        });
    }
};
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Prop(String)
], default_1.prototype, "callback", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
