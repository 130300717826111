import Clipboard from 'v-clipboard';
import Vue from 'vue';

import '@common/index';

import './registerServiceWorker';

import { cancelAllPosts } from '@common/communication';
import App from './App.vue';
import router from './router';
import store from './store';
import "./style.css";

import '@progress/kendo-theme-bootstrap';

Vue.use(Clipboard);

Vue.mixin({
	beforeRouteLeave(to, from, next): void {
		cancelAllPosts();
		next();
	},
});

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app');
