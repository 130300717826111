export var MerchantKioskType;
(function (MerchantKioskType) {
    MerchantKioskType[MerchantKioskType["admin"] = 0] = "admin";
    MerchantKioskType[MerchantKioskType["share"] = 1] = "share";
    MerchantKioskType[MerchantKioskType["moderator"] = 2] = "moderator";
    MerchantKioskType[MerchantKioskType["accountant"] = 3] = "accountant";
})(MerchantKioskType || (MerchantKioskType = {}));
export var TagType;
(function (TagType) {
    TagType[TagType["Item"] = 1] = "Item";
    TagType[TagType["Kiosk"] = 2] = "Kiosk";
    TagType[TagType["KioskCategory"] = 3] = "KioskCategory";
})(TagType || (TagType = {}));
