var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
let default_1 = class default_1 extends Vue {
    get play() {
        if (!this.data)
            return 0;
        let result = 0;
        for (let r of this.data)
            result += r.Total;
        return result;
    }
    get profit() {
        if (!this.data)
            return 0;
        let result = 0;
        for (let r of this.data) {
            const profit = r.Profit;
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            if (profit < -1000)
                console.log(r);
            result += profit;
        }
        return Math.floor(result);
    }
};
__decorate([
    Prop()
], default_1.prototype, "data", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
