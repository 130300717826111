var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component } from 'vue-property-decorator';
import dialogs from '@common/dialogs';
import Dialog from './dialog';
let Alert = class Alert extends Dialog {
    constructor() {
        super(...arguments);
        this.title = "";
        this.icon = "";
        this.btn = "";
        this.value = undefined;
    }
    mounted() {
        // 把自身註冊為 dialogs 模組的 alert 代理
        dialogs.alert = (...args) => this.showIcon(...args);
    }
    showIcon(message, title, icon, btn = '確定') {
        this.title = title;
        this.icon = icon;
        this.btn = btn;
        return this.show(message.replace(/\n/g, '<br>'));
    }
};
Alert = __decorate([
    Component
], Alert);
export default Alert;
