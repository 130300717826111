
	import { Component, Inject, Prop } from "vue-property-decorator";

	import { now, today } from "@common/date";
	import { sum } from "@/views/utility";
	import Base from "../Base";
	import Detail from "./components/Detail.vue";
	import Revenue from "./components/Revenue.vue";
	import Summary from "./components/Summary.vue";
	import WifiStatus from "./components/WifiStatus.vue";

	import Statistics from "./components/Statistics.vue";
	import { resetState } from "./Index.vue";

	import { threeMonthAgo } from "./util";
	import type { StatisticsItem } from "./components/Statistics.vue";
	import type { KioskModel } from "@common/models/kiosk";
	import type { KioskDetailReport } from "@/models/Report";
	import type { NavigationGuardNext, Route } from "vue-router";

	import type DateRange from "@common/components/form/DateRange.vue";

	export const cache: {
		query: { start: Date, end: Date, init: number } | null
	} = { query: null };

	const COIN_TIMEOUT = 4000;
	const TIMEOUT = 15000;

	@Component({ components: { Statistics, Detail, Revenue, Summary, WifiStatus } })
	export default class Page extends Base {
		@Inject() public readonly alert!: AlertMethod;
		@Inject() public readonly confirm!: ConfirmMethod;
		@Prop(String) public id!: string; // AccountKioskId
		@Prop(String) public date?: string;

		$refs!: {
			range: DateRange;
		};

		protected start: Date = today();
		protected end: Date = today().addDays(1).addSeconds(-1);
		protected data: KioskDetailReport | null = null;
		protected init?: number | null = null;
		protected loading: boolean = false;

		protected get giftout(): number {
			if(!this.data) return 0;
			return sum(this.data.Daily, d => d.Giftout);
		}

		created(): void {
			if(this.date) {
				this.start = this.end = new Date(Date.parse(this.date));
				this.end = this.end.addDays(1).addSeconds(-1);
				this.init = 4;
			}
		}

		mounted(): void {
			this.load();
		}

		beforeMount(): void {
			// console.log(cache.query);
			if(cache.query) {
				this.start = cache.query.start;
				this.end = cache.query.end;
				this.init = cache.query.init;
			}
			cache.query = null;
		}

		beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
			if(to.path.startsWith('/report/Daily')) {
				cache.query = {
					start: this.start,
					end: this.end,
					init: this.$refs.range.active,
				};
			}
			next();
		}

		protected get min(): Date {
			if(!this.data) return new Date();
			return threeMonthAgo(this.data.MerchantKiosk.StartTime);
		}

		protected get statistics(): StatisticsItem[] {
			if(!this.data) return [];
			return this.data.Daily.map(item => ({
				title: item.Date.format(),
				play: item.Total,
				gift: item.Giftout,
			}));
		}

		protected get kiosk(): KioskModel {
			return this.data!.MerchantKiosk.Kiosk;
		}

		protected daily(d: string): void {
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			this.$router.push(`/report/Daily/${this.id}/${encodeURIComponent(d.substr(0, 10).replace(/\//g, "-"))}`);
		}

		protected get title(): string {
			if(!this.data) return "";
			return this.data.MerchantKiosk.Name;
		}

		protected onchange(e: [Date, Date]): void {
			if(e.length < 2) return;
			if(!e[1]) e[1] = e[0];
			[this.start, this.end] = e;
			this.load();
		}

		private async load(background = false): Promise<void> {
			if(!background) this.loading = true;
			try {
				this.data = await post<KioskDetailReport>("/api/Report/Detail", {
					Id: this.id,
					Start: this.start.toIsoTime(),
					End: this.end.toIsoTime(),
				}, TIMEOUT);
				// 下面這一列為偵錯用
				// console.log(this.data);
				this.loading = false;
				this.update = now();
			} catch(e: unknown) {
				if(background) return; // 背景執行模式的話忽略錯誤
				if(e instanceof Error) await this.alert(e.message || "聯繫伺服器逾時", "讀取失敗", "error");
				this.$router.back();
			}
		}

		protected onCoin(): void {
			// 刷新兩次看看能不能捕捉到投幣次數更新
			this.load(true);
			setTimeout(() => this.load(true), COIN_TIMEOUT);
			resetState();
		}
	}
