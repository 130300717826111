export function isElementInViewport(el) {
    var rect = el.getBoundingClientRect();
    return rect.bottom > 0 &&
        rect.right > 0 &&
        rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
        rect.top < (window.innerHeight || document.documentElement.clientHeight);
}
window.addEventListener('resize', e => {
    let el = document.activeElement;
    if (el && !isElementInViewport(el))
        el.scrollIntoView();
});
