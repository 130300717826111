import './polyfill';
import Component from 'vue-class-component';
import Vue from 'vue';
import './date';
import './communication';
import './components';
import './jsInterface';
import './number';
import './validate';
import './viewport';
import './kendo';
Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate',
]);
Vue.config.productionTip = false;
