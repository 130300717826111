/////////////////////////////////////
// 設備管理
const routes = [
    {
        path: '/share',
        component: () => import(/* webpackChunkName: "share" */ '@/views/share/Index.vue'),
    },
    {
        path: '/share/add',
        component: () => import(/* webpackChunkName: "share" */ '@/views/share/Add.vue'),
    },
    {
        path: '/share/detail/:id',
        props: true,
        component: () => import(/* webpackChunkName: "share" */ '@/views/share/Detail.vue'),
    },
    {
        path: '/share/setting/:id',
        props: true,
        component: () => import(/* webpackChunkName: "share" */ '@/views/share/Setting.vue'),
    },
];
export default routes;
