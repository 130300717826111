import Vue from 'vue';
import Vuex from 'vuex';
import { settings } from '@common/communication';
Vue.use(Vuex);
const store = new Vuex.Store({
    state: {
        hideBack: true,
        hideMenu: false,
        containerClass: "",
        accessToken: null,
        refreshToken: null,
    },
    mutations: {
        setHideBack(state, hideBack) {
            state.hideBack = hideBack;
        },
        setHideMenu(state, hideMenu) {
            state.hideMenu = hideMenu;
        },
        setContainerClass(state, className) {
            state.containerClass = className !== null && className !== void 0 ? className : "bg-white";
        },
        setAccessToken(state, payload) {
            var _a, _b;
            state.accessToken = (_a = payload === null || payload === void 0 ? void 0 : payload.AccessToken) !== null && _a !== void 0 ? _a : null;
            let r = (_b = payload === null || payload === void 0 ? void 0 : payload.RefreshToken) !== null && _b !== void 0 ? _b : null;
            state.refreshToken = r;
            if (r)
                localStorage.setItem("token", r);
            else
                localStorage.removeItem("token");
        },
    },
    actions: {},
    modules: {},
});
settings.adapter = {
    getAccessToken: () => store.state.accessToken,
    getRefreshToken: () => store.state.refreshToken,
    setToken: v => store.commit('setAccessToken', v),
};
export default store;
