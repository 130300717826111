var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Component, Vue, Watch } from "vue-property-decorator";
import { now } from "@common/date";
import { TagType } from "@common/models/kiosk";
import TitleModal from "@/components/modal/TitleModal.vue";
import FilterModal from "@/components/modal/FilterModal.vue";
import Sorter, { sort } from "@/components/Sorter.vue";
import { setScroll } from "@/views/setScroll";
import { sum } from "@/views/utility";
import { toIndexQuery, newQuery } from "@/models/Report";
import Base from "../Base";
import Revenue from "./components/Revenue.vue";
import ExcelModal from "./modal/ExcelModal.vue";
import Kiosk from "./components/Kiosk.vue";
import Summary from "./components/Summary.vue";
import { cache as DetailCache } from "./Detail.vue";
import { threeMonthAgo } from "./util";
let cache = null;
export function resetState() {
    if (cache)
        cache.state = undefined;
}
export function resetCache() {
    cache = null;
    DetailCache.query = null;
}
const TIMEOUT = 120000;
let default_1 = class default_1 extends Base {
    constructor() {
        super(...arguments);
        this.data = null;
        this.summary = [];
        this.query = newQuery();
        this.tags = [];
        this.categories = [];
        this.sorting = [0, true];
        this.sortingOption = [
            "名稱", "連線狀態", "啟動次數", "出貨數", "累保金額", "毛利", "毛利率",
        ];
        this.sortingSelectors = [
            (a) => a.Name,
            (a) => Number(a.IsTimeout),
            (a) => sum(a.Reports, r => r.Total),
            (a) => sum(a.Reports, r => r.Giftout),
            (a) => { var _a; return (_a = a.AccumulatedCoin) !== null && _a !== void 0 ? _a : 0; },
            (a) => { var _a, _b; return (_b = (_a = a.Reports[a.Reports.length - 1]) === null || _a === void 0 ? void 0 : _a.AverageProfit) !== null && _b !== void 0 ? _b : Number.NEGATIVE_INFINITY; },
            (a) => { var _a, _b; return (_b = (_a = a.Reports[a.Reports.length - 1]) === null || _a === void 0 ? void 0 : _a.ProfitRate) !== null && _b !== void 0 ? _b : Number.NEGATIVE_INFINITY; },
        ];
    }
    get min() {
        return threeMonthAgo();
    }
    get active() {
        var _a, _b;
        return this.sort((_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.Kiosks.filter(d => !d.EndTime)) !== null && _b !== void 0 ? _b : []);
    }
    get inactive() {
        var _a, _b;
        return this.sort((_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.Kiosks.filter(d => d.EndTime)) !== null && _b !== void 0 ? _b : []);
    }
    get disconnectCount() {
        return this.active.filter(k => k.IsTimeout).length;
    }
    sort(list) {
        return sort(list, this.sortingSelectors[this.sorting[0]], this.sorting[1]);
    }
    onSorting(v) {
        localStorage.setItem("reportSorting", v[0].toString());
        localStorage.setItem("reportAscending", String(v[1]));
    }
    beforeMount() {
        const sorting = localStorage.getItem("reportSorting");
        if (sorting != null)
            this.sorting[0] = Number(sorting);
        const ascending = localStorage.getItem("reportAscending");
        if (ascending != null)
            this.sorting[1] = ascending == "true";
        post('/api/tag', { Type: TagType.Kiosk }).then(v => this.tags = v);
        post('/api/tag', { Type: TagType.KioskCategory }).then(v => this.categories = v);
        let scroll = 0;
        if (cache) {
            this.query = cache.query;
            if (cache.state) {
                scroll = cache.state.scroll;
                this.data = cache.state.data;
                this.summary = this.data.Kiosks.flatMap(d => d.Reports);
                this.time = cache.state.time;
                Vue.nextTick(() => setScroll(scroll));
            }
            else {
                this.load();
            }
            resetCache();
        }
        else {
            this.load();
        }
    }
    beforeRouteLeave(to, from, next) {
        if (to.path.startsWith('/report/detail') || to.path.startsWith('/item/detail')) {
            DetailCache.query = {
                start: this.query.start,
                end: this.query.end,
                init: this.query.init,
            };
            cache = {
                query: this.query,
                state: {
                    time: this.time,
                    data: this.data,
                    scroll: document.querySelector('main').scrollTop,
                },
            };
        }
        else {
            resetCache();
        }
        next();
    }
    showFilter() {
        return __awaiter(this, void 0, void 0, function* () {
            if (yield this.$refs.filter.show())
                yield this.load();
        });
    }
    edit(k) {
        var m = this.$refs.modal;
        m.model = k;
        m.show();
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            this.summary = [];
            try {
                this.data = yield post("/api/Report", toIndexQuery(this.query), TIMEOUT);
                this.summary = this.data.Kiosks.flatMap(d => d.Reports);
                this.update = now();
            }
            catch (e) {
                this.data = null;
                throw e;
            }
        });
    }
    add() {
        this.$router.push('/device/scan');
    }
    excel() {
        this.$refs.excel.show();
    }
    get giftout() {
        if (!this.data)
            return "";
        let result = 0;
        for (let r of this.data.Kiosks.flatMap(k => k.Reports))
            result += r.Giftout;
        return result.toComma();
    }
};
__decorate([
    Watch("sorting", { deep: true })
], default_1.prototype, "onSorting", null);
default_1 = __decorate([
    Component({ components: { Kiosk, Summary, FilterModal, ExcelModal, TitleModal, Sorter, Revenue } })
], default_1);
export default default_1;
