var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { TerminalPromotionStatus as Status } from "@/models/Owner";
const routeMap = {
    "buyandgetfree": "/service/bgf",
    "eventgiveaway": "/owner/eventGiveaway",
};
let default_1 = class default_1 extends Vue {
    get promotionClass() {
        if (this.data.Status == Status.Inviting || this.data.Status == Status.None)
            return "border-warning text-warning";
        if (this.data.Status == Status.Activated)
            return "border-success text-success";
        if (this.data.Status == Status.Deactivated)
            return "border-danger text-danger";
        return "";
    }
    get promotionIconClass() {
        if (this.data.Status == Status.Activated)
            return "fas fa-check-circle text-success";
        if (this.data.Status == Status.Deactivated)
            return "fas fa-ban text-danger";
        return "";
    }
    promotion() {
        if (this.share) {
            this.alert("限主帳號操作", undefined, "warning");
            return;
        }
        if (this.data.Status == Status.None) {
            const route = routeMap[this.data.CodeName];
            if (route)
                this.$router.push(route);
        }
        if (this.data.Status == Status.Inviting)
            this.$emit("click");
    }
};
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Prop()
], default_1.prototype, "data", void 0);
__decorate([
    Prop()
], default_1.prototype, "share", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
