var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
/** 這是自動會調整高度的 `<textarea>` 元件 */
let default_1 = class default_1 extends Vue {
    mounted() {
        const el = this.$el;
        const min = el.getBoundingClientRect().height;
        const offset = min - el.scrollHeight;
        el.oninput = () => {
            el.style.height = "0";
            el.style.height = Math.max(min, el.scrollHeight + offset) + "px";
        };
    }
};
__decorate([
    Prop()
], default_1.prototype, "value", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
