

	import { Component, Inject, Prop, Vue } from "vue-property-decorator";
	import { TerminalPromotionStatus } from "@/models/Owner";
	import { leapaItemTypeString } from "@/models/Item";
	import { sum } from "@/views/utility";
	import WifiStatus from "./WifiStatus.vue";
	import Promotion from "./Promotion.vue";

	import type { TerminalPromotionModel } from "@/models/Owner";
	import type { ReportIndexModel, ReportModel } from "@/models/Report";

	@Component({ components: { Promotion, WifiStatus } })
	export default class extends Vue {
		@Inject() public readonly confirm!: ConfirmMethod;
		@Inject() public readonly alert!: AlertMethod;

		@Prop(Number) public index!: number;
		@Prop(Date) public start!: Date;
		@Prop() public data!: ReportIndexModel;

		protected readonly leapaItemTypeString = leapaItemTypeString;

		protected detail(): void {
			let url = '/report/detail/' + this.data.Id;
			let end = this.data.EndTime;
			if(end) url += "/" + encodeURIComponent(end.toISOString());
			this.$router.push(url);
		}

		protected get total(): number {
			return sum(this.data.Reports, r => r.Total);
		}

		protected get giftout(): number {
			return sum(this.data.Reports, r => r.Giftout);
		}

		protected get accumulated(): string | null {
			var a = this.data.AccumulatedCoin;
			return typeof a == 'number' ? "$" + a.toComma() : null;
		}

		protected get lastReport(): ReportModel | null {
			if(!this.data.Reports.length) return null;
			return this.data.Reports[this.data.Reports.length - 1];
		}

		protected item(): void {
			this.$router.push('/item/detail/' + this.data.Id);
		}

		protected async accept(p: TerminalPromotionModel): Promise<void> {
			if(!await this.confirm(`設備：${this.data.Name}<br>編號：${this.data.Kiosk.DisplayCode}`, "啟用" + p.Name)) return;
			try {
				post("/api/Owner/AcceptInvitation", { Id: p.Id });
				p.Status = TerminalPromotionStatus.Activated;
				await this.alert("啟用成功", undefined, "success");
			} catch(e) {
				if(e instanceof Error) this.alert(e.message, "啟用失敗", "danger");
			}
		}
	}
