/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable no-extend-native */
const wd = ["日", "一", "二", "三", "四", "五", "六"];
/** 產生相當於是台灣時區的時間物件 */
export function now() {
    return new Date(new Date().toLocaleString('en', { timeZone: 'Asia/Taipei' }));
}
export function format(d) {
    return toDateString(d, "/") + " (" + wd[d.getDay()] + ")";
}
export function toDateString(d, separator = "-", year = true) {
    const prefix = year ? d.getFullYear() + separator : "";
    return prefix + pad(d.getMonth() + 1) + separator + pad(d.getDate());
}
export function second() {
    return format(this) + " " + this.time();
}
export function minute(year = true) {
    return toDateString(this, "/", year) + " " + pad(this.getHours()) + ":" + pad(this.getMinutes());
}
export function time() {
    return pad(this.getHours()) + ":" + pad(this.getMinutes()) + ":" + pad(this.getSeconds());
}
export function pad(n) {
    return n.toString().padStart(2, '0');
}
export function today() {
    return now().datePart();
}
export function toISO(d) {
    return toDateString(d) + "T00:00:00";
}
Date.prototype.time = time;
Date.prototype.second = second;
Date.prototype.minute = minute;
Date.prototype.datetime = function () {
    return toDateString(this, '/') + ' ' + this.time();
};
Date.prototype.date = function () { return toDateString(this, '/'); };
Date.prototype.format = function () { return format(this); };
Date.prototype.toISO = function () { return toISO(this); };
Date.prototype.toIsoTime = function () {
    return toDateString(this) + "T" + this.time() + "." + this.getMilliseconds().toString().padStart(3, '0');
};
Date.prototype.clone = function () { return new Date(this); };
Date.prototype.addDays = function (days) {
    const date = this.clone();
    date.setDate(date.getDate() + days);
    return date;
};
Date.prototype.addSeconds = function (s) {
    const date = this.clone();
    date.setSeconds(date.getSeconds() + s, 0);
    return date;
};
Date.prototype.addMilliseconds = function (ms) {
    const date = this.clone();
    date.setHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds() + ms);
    return date;
};
Date.prototype.datePart = function () {
    const result = this.clone();
    result.setHours(0, 0, 0, 0);
    return result;
};
function match(d, h, m, s) {
    return d.getHours() == h && d.getMinutes() == m && d.getSeconds() == s;
}
export function hasTime(start, end) {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return !match(start, 0, 0, 0) || !match(end, 23, 59, 59);
}
