import { render, staticRenderFns } from "./ArrowDetails.vue?vue&type=template&id=5aff2270&scoped=true&"
import script from "./ArrowDetails.vue?vue&type=script&lang=ts&"
export * from "./ArrowDetails.vue?vue&type=script&lang=ts&"
import style0 from "./ArrowDetails.vue?vue&type=style&index=0&id=5aff2270&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aff2270",
  null
  
)

export default component.exports