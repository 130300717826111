var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { MerchantKioskType } from "@common/models/kiosk";
import TitleModal from "@/components/modal/TitleModal.vue";
import QRModal from "@/components/modal/QRModal.vue";
import { resetState } from "../Index.vue";
import WifiModal from "../modal/WifiModal.vue";
import CoinSlot from "./CoinSlot.vue";
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.tags = [];
    }
    beforeMount() {
        post('/api/tag').then(v => this.tags = v);
    }
    get title() {
        if (!this.data)
            return "";
        return this.data.Kiosk.Name;
    }
    get admin() {
        var _a;
        return ((_a = this.data) === null || _a === void 0 ? void 0 : _a.Type) == MerchantKioskType.admin;
    }
    edit() {
        var m = this.$refs.modal;
        m.model = this.data;
        m.show();
    }
    coin() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!(yield this.confirm('確定要遠端投幣嗎？', '遠端投幣', 'warning')))
                return;
            yield post('/api/Device/Coin', { Id: this.data.Id });
            yield this.alert('目前僅發出投幣指令，\n投幣是否成功尚需現場人員確認。', '指令已發送', 'success');
            this.$emit('coin');
        });
    }
    reset() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!(yield this.confirm('<ol class="pl-3 text-left"><li>按下重置後，設備將解除帳號綁定<li>此設備即可設定在其它帳號/機台上<li>營收報表中仍可查閱機台歷史營收<li>當設備解除帳號綁定時，原綁定的共享帳號皆會失去權限</ol>', '解除帳號綁定', 'danger', '重置')))
                    return;
                yield post('/api/Device/Reset', { Id: this.data.Id });
                resetState();
                this.$router.back();
            }
            catch (e) {
                if (e instanceof Error)
                    yield this.alert(e.message, "解除帳號綁定失敗", "error");
            }
        });
    }
    cancelShare() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!(yield this.confirm('確定要取消裝置共享嗎？這將會使這台裝置從您的列表中永久移除。', '取消共享', 'warning')))
                    return;
                yield post('/api/Device/CancelShare', { Id: this.data.Id });
                resetState();
                this.$router.back();
            }
            catch (e) {
                if (e instanceof Error)
                    yield this.alert(e.message, "取消失敗", "error");
            }
        });
    }
    qr() {
        this.$refs.qr.show(this.data.Kiosk.HardwareCode, this.data.Kiosk.Url);
    }
    wifi() {
        this.$refs.wifi.show(this.data.Kiosk.HardwareCode);
    }
};
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Inject()
], default_1.prototype, "confirm", void 0);
__decorate([
    Prop()
], default_1.prototype, "data", void 0);
default_1 = __decorate([
    Component({ components: { CoinSlot, QRModal, TitleModal, WifiModal } })
], default_1);
export default default_1;
