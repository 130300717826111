var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Inject, Vue } from "vue-property-decorator";
function getAPI() {
    switch (FLAVOR) {
        case 'DEV': return '_dev';
        case 'QA': return '_qa';
        case 'PUB': return ''; // 20220905：正式區打通用的 API，以免版本錯誤的板子綁定到 PUB 來
        default: return '';
    }
}
export const HOST = 'http://192.168.100.1';
const URL = HOST + '/bind' + getAPI();
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.token = "";
    }
    created() {
        post("/api/Info").then(v => this.token = v.Id);
    }
    toWifi() {
        jsInterface.toWifi();
    }
    search() {
        let url = URL + "?token=" + this.token;
        if (navigator.userAgent.includes("iPhone"))
            location.href = url;
        else
            this.$router.push("/device/hub/" + encodeURIComponent(url));
    }
};
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
