var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import { MINI_CLASS } from "@common/components/button";
let ButtonSelect = class ButtonSelect extends Vue {
    constructor() {
        super(...arguments);
        this.cls = MINI_CLASS + " btn-secondary";
    }
};
__decorate([
    Prop()
], ButtonSelect.prototype, "value", void 0);
__decorate([
    Prop()
], ButtonSelect.prototype, "options", void 0);
__decorate([
    Prop()
], ButtonSelect.prototype, "btnClass", void 0);
__decorate([
    Prop(Boolean)
], ButtonSelect.prototype, "arrow", void 0);
ButtonSelect = __decorate([
    Component
], ButtonSelect);
export default ButtonSelect;
