export function share(url, text, title) {
    if ('share' in navigator)
        navigator.share({ url, text, title });
    else
        jsInterface.share(url, text, title);
}
/**
 * 判斷是否當前的環境為改版之前的舊版 Android App
 */
export function isLegacyAndroid() {
    return !("share" in navigator) && // Android WebView 沒有定義這個
        "share" in jsInterface && // Android 以外的環境不會有這個
        !("nfcStart" in jsInterface); // 舊版的 Android App 沒有這個
}
