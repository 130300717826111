var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Watch } from "vue-property-decorator";
import { MerchantKioskType } from "@common/models/kiosk";
import Modal from "@/components/modal";
let default_1 = class default_1 extends Modal {
    constructor() {
        super(...arguments);
        this.model = null;
        this.tags = [];
        this.title = "";
    }
    onModel(v) {
        if (v) {
            this.title = v.Name;
            this.tags = v.Tags.concat();
        }
    }
    submit() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.model)
                return;
            const model = this.model;
            const old = model.Name, oldTags = model.Tags;
            model.Name = this.title;
            model.Tags = this.tags;
            const title = // 如果是共享帳號而且名稱剛好設定得跟主帳號一樣，那就把共享帳號自訂的名稱清掉、以便跟著主帳號走
             model.Type != MerchantKioskType.admin && this.title == this.model.ActiveMerchantKioskName ?
                null : this.title.trim() || null;
            try {
                yield post('/api/Device/Edit', {
                    Id: model.Id,
                    Name: title,
                    Tags: this.tags.map(t => this.options.find(o => o.Name == t).Id),
                });
            }
            catch (e) {
                model.Name = old;
                model.Tags = oldTags;
                yield this.alert('儲存失敗，請稍後再試', '', 'warning');
            }
            this.model = null;
        });
    }
    btnClass(o, i) {
        return (this.tags.includes(o.Name) ? "btn-secondary" : "btn-light") + (i > 0 ? " ml-2" : "");
    }
    toggle(o) {
        let t = o.Name, i = this.tags.indexOf(t);
        if (i >= 0)
            this.tags.splice(i, 1);
        else
            this.tags.push(t);
    }
    resetName() {
        if (!this.model)
            return;
        this.title = this.model.ActiveMerchantKioskName;
    }
};
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Prop()
], default_1.prototype, "options", void 0);
__decorate([
    Watch("model")
], default_1.prototype, "onModel", null);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
