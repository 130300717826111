
	import { Component, Prop, Vue } from "vue-property-decorator";

	@Component({ components: {} })
	export default class PlusMinus extends Vue {
		@Prop() public value!: number;
		@Prop() public min?: number;
		@Prop() public rules?: string | object;
		@Prop() public placeholder?: string;

		protected get noMinus(): boolean {
			if(typeof this.min != "number") return false;
			return this.value <= this.min;
		}

		protected change(by: number): void {
			this.$emit("input", this.value + by);
		}
	}
