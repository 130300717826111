var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
export const tabKey = Symbol("tab");
let Tab = class Tab extends Vue {
    get cls() {
        if (this.context.tabs.indexOf(this) == this.context.index)
            return "show";
        return "";
    }
    mounted() {
        this.context.tabs.push(this);
    }
};
__decorate([
    Inject(tabKey)
], Tab.prototype, "context", void 0);
__decorate([
    Prop()
], Tab.prototype, "title", void 0);
Tab = __decorate([
    Component
], Tab);
export default Tab;
