var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import { MultiSelect } from '@progress/kendo-vue-dropdowns';
let IncludeExclude = class IncludeExclude extends Vue {
    sync() {
        this.temp[this.include] = this.temp[this.include].filter(id => !this.temp[this.exclude].includes(id));
    }
    syncEx() {
        this.temp[this.exclude] = this.temp[this.exclude].filter(id => !this.temp[this.include].includes(id));
    }
};
__decorate([
    Prop()
], IncludeExclude.prototype, "data", void 0);
__decorate([
    Prop()
], IncludeExclude.prototype, "temp", void 0);
__decorate([
    Prop()
], IncludeExclude.prototype, "label", void 0);
__decorate([
    Prop()
], IncludeExclude.prototype, "include", void 0);
__decorate([
    Prop()
], IncludeExclude.prototype, "exclude", void 0);
IncludeExclude = __decorate([
    Component({ components: { MultiSelect } })
], IncludeExclude);
export default IncludeExclude;
