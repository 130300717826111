/////////////////////////////////////
// 支付申請人功能
const routes = [
    {
        path: '/merchant',
        component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/Index.vue'),
    },
    {
        path: '/merchant/apply',
        component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/apply/Index.vue'),
    },
    {
        path: '/merchant/verify/:id',
        props: true,
        component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/apply/Verify.vue'),
        meta: {
            anonymous: true,
        },
    },
    {
        path: '/merchant/edit',
        component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/Edit.vue'),
    },
    {
        path: '/merchant/outlet/edit/:id?',
        props: true,
        component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/outlet/Edit.vue'),
    },
    {
        path: '/merchant/device/index/:id',
        props: true,
        component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/device/Index.vue'),
    },
    {
        path: '/merchant/device/scan/:id',
        props: true,
        component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/device/Scan.vue'),
    },
    {
        path: '/merchant/match/index/:id',
        props: true,
        component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/match/Index.vue'),
    },
    {
        path: '/merchant/match/accept/:id',
        props: true,
        component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/match/Accept.vue'),
    },
    {
        // 這個路由的存在是因為 LeaPA 點支付的系統代碼為「token」
        path: '/merchant/payment/token',
        redirect: '/merchant/payment/leapa',
    },
    {
        path: '/merchant/payment/leapa',
        component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/payment/Leapa/Index.vue'),
    },
    {
        path: '/merchant/payment/scan2paytaiwan',
        component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/payment/Scan2PayTaiwan/Index.vue'),
    },
    {
        path: '/merchant/payment/scan2payline',
        component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/payment/Scan2PayLine/Index.vue'),
    },
    {
        path: '/merchant/payment/scan2payjk',
        component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/payment/Scan2PayJK/Index.vue'),
    },
    {
        path: '/merchant/payment/scan2paypi',
        component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/payment/Scan2PayPi/Index.vue'),
    },
    {
        path: '/merchant/payment/scan2paycr',
        component: () => import(/* webpackChunkName: "merchant" */ '@/views/merchant/payment/Scan2PayCR/Index.vue'),
    },
];
export default routes;
