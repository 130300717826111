var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
let default_1 = class default_1 extends Vue {
    get formatTitle() {
        var _a;
        let result = (_a = this.title) === null || _a === void 0 ? void 0 : _a.replace(/\n/g, "<br>");
        if (this.remark)
            result += `<span class="text-secondary" style="font-size:1rem;">${this.remark}</span>`;
        return result;
    }
};
__decorate([
    Prop(String)
], default_1.prototype, "title", void 0);
__decorate([
    Prop({ default: '' })
], default_1.prototype, "remark", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
