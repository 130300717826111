var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import * as bootstrap from 'bootstrap';
import { HOST } from '@/views/device/Add.vue';
let default_1 = class extends Vue {
    constructor() {
        super(...arguments);
        this.code = "";
    }
    mounted() {
        this.modal = new bootstrap.Modal(this.$el);
    }
    show(code) {
        this.code = code;
        this.modal.show();
    }
    ok() {
        this.modal.hide();
        const url = HOST + '/';
        if (navigator.userAgent.includes("iPhone"))
            location.href = url;
        else
            this.$router.push("/device/hub/" + encodeURIComponent(url));
    }
    toWifi() {
        jsInterface.toWifi();
    }
};
default_1 = __decorate([
    Component
], default_1);
export default default_1;
