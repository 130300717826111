var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { TerminalPromotionStatus } from "@/models/Owner";
import { leapaItemTypeString } from "@/models/Item";
import { sum } from "@/views/utility";
import Promotion from "./Promotion.vue";
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.leapaItemTypeString = leapaItemTypeString;
    }
    detail() {
        let url = '/report/detail/' + this.data.Id;
        let end = this.data.EndTime;
        if (end)
            url += "/" + encodeURIComponent(end.toISOString());
        this.$router.push(url);
    }
    get total() {
        return sum(this.data.Reports, r => r.Total);
    }
    get giftout() {
        return sum(this.data.Reports, r => r.Giftout);
    }
    get accumulated() {
        var a = this.data.AccumulatedCoin;
        return typeof a == 'number' ? "$" + a.toComma() : null;
    }
    get lastReport() {
        if (!this.data.Reports.length)
            return null;
        return this.data.Reports[this.data.Reports.length - 1];
    }
    item() {
        this.$router.push('/item/detail/' + this.data.Id);
    }
    accept(p) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!(yield this.confirm(`設備：${this.data.Name}<br>編號：${this.data.Kiosk.DisplayCode}`, "啟用" + p.Name)))
                return;
            try {
                post("/api/Owner/AcceptInvitation", { Id: p.Id });
                p.Status = TerminalPromotionStatus.Activated;
                yield this.alert("啟用成功", undefined, "success");
            }
            catch (e) {
                if (e instanceof Error)
                    this.alert(e.message, "啟用失敗", "danger");
            }
        });
    }
};
__decorate([
    Inject()
], default_1.prototype, "confirm", void 0);
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Prop(Number)
], default_1.prototype, "index", void 0);
__decorate([
    Prop(Date)
], default_1.prototype, "start", void 0);
__decorate([
    Prop()
], default_1.prototype, "data", void 0);
default_1 = __decorate([
    Component({ components: { Promotion } })
], default_1);
export default default_1;
