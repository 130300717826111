var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
const SECTION_UNIT = 60;
const TEN = 10;
const ONE_SECOND = 1000;
//let controller: AbortController | undefined;
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        //mounted(): void {
        //	if('OTPCredential' in window) {
        //		controller = new AbortController();
        //		navigator.credentials.get({
        //			otp: { transport: ['sms'] },
        //			signal: controller.signal,
        //		})
        //			.then(otp => this.$emit('input', otp.code));
        //	}
        //}
        //destroyed(): void {
        //	if(controller) controller.abort();
        //	controller = undefined;
        //}
        this.time = 0;
    }
    get formatTime() {
        let min = Math.floor(this.time / SECTION_UNIT);
        let sec = this.time % SECTION_UNIT;
        return (min > 0 ? min + ":" : "") + (sec < TEN ? "0" : "") + sec;
    }
    send() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.time = -1; // 代表發送中
                let cellphone = yield this._events.send[0]();
                if (!cellphone) {
                    this.time = 0;
                    return;
                }
                yield post('/api/Login/RequestOTP', {
                    Subject: cellphone,
                    Registered: Boolean(this.reg),
                });
                this.time = 120;
                let int = setInterval(() => {
                    this.time--;
                    if (this.time == 0)
                        clearInterval(int);
                }, ONE_SECOND);
                this.$el.querySelector("input.form-control").focus();
            }
            catch (e) {
                this.time = 0;
                if (e instanceof Error)
                    this.alert(e.message, "無法傳送驗證碼", "warning");
            }
        });
    }
};
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Prop(String)
], default_1.prototype, "value", void 0);
__decorate([
    Prop(Boolean)
], default_1.prototype, "reg", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
