/* eslint-disable max-lines */
import { today } from "@common/date";
export var TerminalPromotionStatus;
(function (TerminalPromotionStatus) {
    TerminalPromotionStatus[TerminalPromotionStatus["None"] = 0] = "None";
    TerminalPromotionStatus[TerminalPromotionStatus["Inviting"] = 1] = "Inviting";
    TerminalPromotionStatus[TerminalPromotionStatus["Activated"] = 2] = "Activated";
    TerminalPromotionStatus[TerminalPromotionStatus["Deactivated"] = -1] = "Deactivated";
})(TerminalPromotionStatus || (TerminalPromotionStatus = {}));
export var EventGiveawayType;
(function (EventGiveawayType) {
    EventGiveawayType[EventGiveawayType["Ticket"] = 1] = "Ticket";
    EventGiveawayType[EventGiveawayType["Link"] = 2] = "Link";
    EventGiveawayType[EventGiveawayType["Distributer"] = 3] = "Distributer";
})(EventGiveawayType || (EventGiveawayType = {}));
export function giveawayTypeToString(type) {
    switch (type) {
        case EventGiveawayType.Link: return "兌換連結條碼";
        case EventGiveawayType.Ticket: return "紙本兌換券";
        case EventGiveawayType.Distributer: return "專人發送";
        default: return "不明類別";
    }
}
export var PromotionStatus;
(function (PromotionStatus) {
    PromotionStatus[PromotionStatus["Active"] = 0] = "Active";
    PromotionStatus[PromotionStatus["Unstarted"] = 1] = "Unstarted";
    PromotionStatus[PromotionStatus["Inactive"] = 2] = "Inactive";
    PromotionStatus[PromotionStatus["Expire"] = 3] = "Expire";
})(PromotionStatus || (PromotionStatus = {}));
export var ShareGiveawayRewardType;
(function (ShareGiveawayRewardType) {
    ShareGiveawayRewardType[ShareGiveawayRewardType["AfterUse"] = 0] = "AfterUse";
    ShareGiveawayRewardType[ShareGiveawayRewardType["AfterReceive"] = 1] = "AfterReceive";
})(ShareGiveawayRewardType || (ShareGiveawayRewardType = {}));
export function newQuery() {
    return {
        Start: today(),
        End: today().addDays(1).addSeconds(-1),
        Includes: [],
        Excludes: [],
        init: 0,
    };
}
