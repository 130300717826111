import { Enum } from "@common/enum";
export var MerchantItemType;
(function (MerchantItemType) {
    MerchantItemType[MerchantItemType["Issuer"] = 1] = "Issuer";
    MerchantItemType[MerchantItemType["Dealer"] = 2] = "Dealer";
    MerchantItemType[MerchantItemType["Collector"] = 4] = "Collector";
})(MerchantItemType || (MerchantItemType = {}));
export var ItemSupplyStatus;
(function (ItemSupplyStatus) {
    ItemSupplyStatus[ItemSupplyStatus["InStock"] = 1] = "InStock";
    ItemSupplyStatus[ItemSupplyStatus["OutOfStock"] = 0] = "OutOfStock";
    ItemSupplyStatus[ItemSupplyStatus["Discontinued"] = -1] = "Discontinued";
})(ItemSupplyStatus || (ItemSupplyStatus = {}));
export var LeapaItemType;
(function (LeapaItemType) {
    LeapaItemType[LeapaItemType["Snack"] = 10] = "Snack";
    LeapaItemType[LeapaItemType["Beverage"] = 20] = "Beverage";
    LeapaItemType[LeapaItemType["InflatedPackage"] = 30] = "InflatedPackage";
    LeapaItemType[LeapaItemType["StuffedToy"] = 40] = "StuffedToy";
    LeapaItemType[LeapaItemType["Toy"] = 50] = "Toy";
    LeapaItemType[LeapaItemType["Figurine"] = 60] = "Figurine";
    LeapaItemType[LeapaItemType["Electronic"] = 70] = "Electronic";
    LeapaItemType[LeapaItemType["Necessity"] = 80] = "Necessity";
    LeapaItemType[LeapaItemType["Other"] = 9999] = "Other";
})(LeapaItemType || (LeapaItemType = {}));
export function leapaItemTypeString(type) {
    switch (type) {
        case LeapaItemType.Snack: return "零食";
        case LeapaItemType.Beverage: return "飲料";
        case LeapaItemType.InflatedPackage: return "空氣包";
        case LeapaItemType.StuffedToy: return "娃娃";
        case LeapaItemType.Toy: return "玩具";
        case LeapaItemType.Figurine: return "公仔";
        case LeapaItemType.Electronic: return "三Ｃ";
        case LeapaItemType.Necessity: return "日用品";
        default: return "其它";
    }
}
export const LeapaItemTypeOptions = Enum.values(LeapaItemType).map(type => ({
    text: leapaItemTypeString(type),
    value: type,
}));
