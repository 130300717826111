var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Provide, Vue } from "vue-property-decorator";
const trigger = 100;
const speed = 1;
const init = 0;
const rotation = 1.5;
let ScrollMain = class ScrollMain extends Vue {
    constructor() {
        super(...arguments);
        this.y = 0;
        this.pullState = 0;
        this.showBackToTop = false;
        this.ctrl = new AbortController();
    }
    mounted() {
        let opt = { signal: this.ctrl.signal };
        document.addEventListener('touchmove', e => this.move(e), opt);
        document.addEventListener('touchend', e => this.end(e), opt);
        document.addEventListener('touchcancel', e => this.end(e), opt);
    }
    beforeDestroy() {
        this.ctrl.abort();
    }
    backToTop() {
        this.$refs.main.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    start(e) {
        if (document.querySelector(".modal.show"))
            return; // 有對話方塊開啟時停用 pull 功能
        this.y = e.touches[0].clientY;
        if (this.$el.scrollTop <= 0 && this.pull)
            this.pullState = 1;
        let style = this.$refs.reload.style;
        style.transition = "none";
    }
    move(e) {
        if (!this.pullState)
            return;
        let y = e.touches[0].clientY;
        if (y < this.y && this.pullState == 1)
            this.pullState = 0;
        if (y > this.y && this.pullState) {
            this.$el.style.overflowY = 'hidden';
            this.pullState = 2;
            let delta = (y - this.y) * speed + init;
            y = Math.min(delta, trigger);
            this.$refs.reload.style.opacity = Math.max(y / trigger, 0).toString();
            this.$refs.reload.style.top = y + "px";
            this.$refs.spin.style.transform = "rotate(" + delta * rotation + "deg)";
        }
    }
    end(e) {
        this.reset();
        if (this.pullState) {
            let y = e.changedTouches[0].clientY;
            if ((y - this.y) * speed + init >= trigger)
                this.pull();
        }
        this.pullState = 0;
    }
    reset() {
        let style = this.$refs.reload.style;
        style.transition = "all 0.2s";
        style.opacity = "0";
        style.top = init + "px";
        this.$el.style.overflowY = 'scroll';
    }
    scroll(e) {
        var top = e.target.scrollTop;
        this.showBackToTop = top > trigger;
        this.$emit('scroll', top);
    }
};
__decorate([
    Prop()
], ScrollMain.prototype, "containerClass", void 0);
__decorate([
    Prop()
], ScrollMain.prototype, "pull", void 0);
__decorate([
    Provide()
], ScrollMain.prototype, "backToTop", null);
ScrollMain = __decorate([
    Component
], ScrollMain);
export default ScrollMain;
